import React, {Component} from 'react';
import './App.css';
import {Route, Switch, withRouter} from 'react-router-dom';

import Login from '../user/login/Login';
import NotFound from '../common/NotFound';
import LoadingIndicator from '../common/LoadingIndicator';

import {notification} from 'antd';
import {checkValidToken} from "../store/actions";
import {privateRoutes, privateUrls, publicRoutes, publicUrls} from "../route/Routes";
import PrivateRouter from "../router/PrivateRouter";
import PublicRouter from "../router/PublicRouter";
import {connect} from "react-redux";

class App extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      isLoading: true
    };

    notification.config({
      placement: 'topRight',
      top: 70,
      duration: 3,
    });
  }
  
  componentDidMount() {
    this.props.checkValidToken().then(() => {
      this.setState({
        isLoading: false
      });
    });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator />;
    }

    return (
      <Switch>
        <Route exact path={privateUrls.login} component={Login} key={privateUrls.login}/>
        {privateRoutes.map(route => (
          <PrivateRouter exact path={route.path} component={route.component} key={route.path}/>
        ))}
        <PrivateRouter path={privateUrls.basePath} component={NotFound} homeUrl={privateUrls.basePath} />
        {publicRoutes.map(route => (
          <PublicRouter exact path={route.path} component={route.component} key={route.path}/>
        ))}
        <PublicRouter component={NotFound} homeUrl={publicUrls.basePath} />
      </Switch>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  checkValidToken: () => dispatch(checkValidToken())
});

export default withRouter(connect(null, mapDispatchToProps)(App));