export function dndConvert(data) {
  const candidates = {};
  const columns = {
    'NEW': {
      id: 'NEW',
      title: 'NEW',
      candidateIds: [],
    },
    'TALENT POOL': {
      id: 'TALENT POOL',
      title: 'TALENT POOL',
      candidateIds: [],
    },
    'HIRED': {
      id: 'HIRED',
      title: 'HIRED',
      candidateIds: [],
    },
    'DECLINED': {
      id: 'DECLINED',
      title: 'DECLINED',
      candidateIds: [],
    },
    'REJECTED': {
      id: 'REJECTED',
      title: 'REJECTED',
      candidateIds: [],
    },
    'UNQUALIFIED': {
      id: 'UNQUALIFIED',
      title: 'UNQUALIFIED',
      candidateIds: [],
    },
    'PROCESSED': {
      id: 'PROCESSED',
      title: 'PROCESSED',
      candidateIds: [],
    },
  };

  data.forEach(val => {
    candidates[val.id] = {
      id: val.id,
      candidateId: val.candidate.id,
      name: val.name,
      email: val.candidate.email,
      phone: val.phone,
      dateOfBirth: val.dateOfBirth,
      nationality: val.nationality,
      currentSalary: val.currentSalary,
      expectedSalary: val.expectedSalary,

      status: val.status,
      applyDate: val.applyDate,

      position: val.vacancy.position,
      function: val.vacancy.function,
      location: val.vacancy.location
    };

    columns[val.status].candidateIds.push(val.id);
  });
  
  return {candidates: candidates, columns: columns}
}