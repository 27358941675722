import {request} from "./BaseAPIRequest";
import {apiUrls} from "../route/Routes";

export const createNote = payload => {
  return request({
      url: apiUrls.note,
      method: 'POST',
      body: JSON.stringify(payload)
  });
};

export const updateNote = payload => {
  return request({
      url: apiUrls.note,
      method: 'PUT',
      body: JSON.stringify(payload)
  });
};