import {request} from "./BaseAPIRequest";
import {apiUrls} from "../route/Routes";

export const updateInquiryStatus = payload => {
  return request({
    url: `${apiUrls.inquiry}/status`,
    method: 'PUT',
    body: JSON.stringify(payload)
  });
};

export const getInquiries = payload => {
  const urlParams = payload && "position=" + payload.position + "&status=" + payload.status + "&location=" + payload.location + "&name=" + payload.name + "&nationality=" + payload.nationality + "&start=" + payload.start + "&end=" + payload.end;
  
  return request({
    url: `${apiUrls.inquiry}?${urlParams}`,
    method: 'GET'
  });
};

export const getById = (id, options = {}) => {
  return request({
    url: `${apiUrls.inquiry}/${id}`,
    method: 'GET',
    ...options
  });
};