import React, { Component } from 'react';
import './Dashboard.css';

class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }
  render() {
    return (
      <div className="dashboard-container">
        <h1>Dashboard</h1>
        <p>Welcome to our dashboard</p>
      </div>
    );
  }
}

export default Dashboard;