import {ACCESS_TOKEN, TYPE_TOKEN} from "./constants";
import {API_BASE_URL} from "../route/Routes";

export const request = (options, isJSONRequest = true) => {
  const headers = new Headers();
  
  if (isJSONRequest) {
    headers.append('Content-Type', 'application/json')
  }
  
  if (localStorage.getItem(ACCESS_TOKEN)) {
    headers.append('Authorization', TYPE_TOKEN + ' ' + localStorage.getItem(ACCESS_TOKEN))
  }
  
  const defaults = { headers: headers };
  options = Object.assign({}, defaults, options);
  
  return fetch(API_BASE_URL + options.url, options)
    .then(response =>
      response.json().then(json => {
        if (!response.ok) {
          return Promise.reject(json);
        }
        
        return json;
      })
    ).catch( error => {
      if (error.status === 401) {
        localStorage.removeItem(ACCESS_TOKEN);
      }
      
      return Promise.reject(error);
    });
};