import React from 'react';
import {Link} from 'react-router-dom';
import {Button, Card, Col, Divider, Form, notification, Row, Select} from 'antd';
import {applyJob, getJobs} from '../publicView/JobAPI';
import './InquiryStyle.css';
import {NOTIFICATION_HEADER} from "../util/constants";
import PersonalDetailForm from "../job/PersonalDetailForm";
import CompetencyForm from "../job/CompetencyForm";
import DocumentForm from "../job/DocumentForm";
import {privateUrls} from "../route/Routes";

class InquiryCreate extends React.Component {
  dateTimeFormat = "YYYY-MM-DD";

  mController = new AbortController();
  mSignal = this.mController.signal;

  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      uploading: false,
      vacancies: []
    };
  }

  componentDidMount = async () => {
    await getJobs({signal: this.mSignal}).then(response => {
      this.setState({
        vacancies: response || []
      });
    }).catch(() => {});
  };

  componentWillUnmount() {
    this.mController.abort();
  }

  handleSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((error, formInput) => {
      if (error) {
        return;
      }

      const { fileList } = this.state;

      formInput.dateOfBirth = formInput.dateOfBirth.format(this.dateTimeFormat);
      formInput.files = fileList;

      applyJob(formInput).then(() => {
        notification.success({
          id: "apply-notification-success",
          message: NOTIFICATION_HEADER,
          description: "A new job application has been submitted successfully.",
        });

        this.props.history.push(privateUrls.inquiryList);
      }).catch(error => {
        let errorMessage = "Sorry! We cannot process your request at the moment. Please try again later.";

        if (error.isFileRelated) {
          let d = [];
          let counter = 1;

          for (let [fileName, errorMessage] of Object.entries(error.messages)) {
            d.push(<p key={`${fileName}-${counter}`}> - {fileName} : {errorMessage}</p>);
            counter++;
          }

          errorMessage = <>{d}</>;
        }

        notification.error({
          id: "apply-notification-error",
          message: NOTIFICATION_HEADER,
          description: errorMessage
        });

        this.setState({
          uploading: false
        });
      });
    });
  };

  handleFileList= fileList => {
    this.setState(() => ({fileList}));
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { vacancies = [] } = this.state;

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
      },
    };

    const { uploading, fileList } = this.state;
    const positionList = vacancies.map(d => <Select.Option key={d.id}>{d.position}</Select.Option>);

    return(
      <div className="inquiry-form-container">
        <h1>Submit New Job Application</h1>
        <Card title="Application Form" id="job-apply-form">
          <Form id="apply-form" layout="vertical" onSubmit={this.handleSubmit}>
            <Form.Item
              {...formItemLayout}
              label="Position"
              hasFeedback
            >
            {getFieldDecorator('vacancyId', {
              rules: [{ required: true, message: 'Job position is required.', whitespace: true }]
            })(
              <Select
                style={{ width: "100%" }}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
              >
                {positionList}
              </Select>
            )}
            </Form.Item>
            <Form.Item
              {...formItemLayout}
              label="Status"
              hasFeedback
            >
            {getFieldDecorator('status', {
              rules: [{ required: true, message: 'Application status is required.', whitespace: true }],
              initialValue: "NEW"
            })(
              <Select style={{ width: "100%" }}>
                <Select.Option value="NEW">NEW</Select.Option>
                <Select.Option value="HIRED">HIRED</Select.Option>
                <Select.Option value="UNQUALIFIED">UNQUALIFIED</Select.Option>
                <Select.Option value="PROCESSED">PROCESSED</Select.Option>
                <Select.Option value="TALENT POOL">TALENT POOL</Select.Option>
                <Select.Option value="REJECTED">REJECTED</Select.Option>
                <Select.Option value="DECLINED">DECLINED</Select.Option>
              </Select>
            )}
            </Form.Item>
            <PersonalDetailForm form={this.props.form} formItemLayout={formItemLayout} />
            <CompetencyForm form={this.props.form} formItemLayout={formItemLayout} />
            <DocumentForm fileList={fileList} handleFileList={this.handleFileList} />
            <Divider />
            <Form.Item>
              <Row>
                <Col xs={{span: 6, offset: 6}} sm={{span: 4, offset: 8}} style={{align: "center"}}>
                  <Button type="primary" htmlType="submit" loading={uploading}>Submit</Button>
                </Col>
                <Col xs={{span: 12}} sm={{span: 4}}>
                  <Link id="inquiry-create" to={privateUrls.inquiryList}>
                    <Button>Cancel</Button>
                  </Link>
                </Col>
              </Row>
            </Form.Item>
          </Form>
        </Card>
      </div>
    );
  }
}

export default Form.create({ name: 'inquiryCreate' })(InquiryCreate);