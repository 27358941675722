import React, { Component } from 'react';
import { Divider, Row, Col } from 'antd';
import './AppFooter.css';

class AppFooter extends Component {
  render() {
    return (
      <footer className='style-two'>
        <div className='auto-container'>
          <Divider />
          <div className='footer-bottom'>
            <Row>
              <Col
                lg={{ span: 12 }}
                md={{ span: 24 }}
                sm={{ span: 24 }}
                className='copy-column'
              >
                <div className='copyright'>
                  &copy; Copyright 1991 - {new Date().getFullYear()} Mitrais
                </div>
              </Col>
              <Col lg={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24 }}>
                <p className='term-condition'>
                  <small>
                    <a href='https://www.mitrais.com/terms-and-condition/'>
                      Terms and Conditions
                    </a>{' '}
                    |{' '}
                    <a href='https://www.mitrais.com/privacy-policy/'>
                      {' '}
                      Privacy Policy
                    </a>
                  </small>
                </p>
              </Col>
            </Row>
          </div>
        </div>
      </footer>
    );
  }
}

export default AppFooter;
