import React, {Component} from 'react';
import {Avatar, Button, Divider, Empty, Form, Icon, Input, List, Modal, notification, Select, Table, Upload} from 'antd';
import './ViewCandidate.css';
import {getById, updateInquiryStatus} from '../util/InquiryAPI';
import {createNote, updateNote} from '../util/NoteAPI';
import XLSX from 'xlsx';
import ListDocument from './ListDocument';
import moment from 'moment';
import {ACCESS_TOKEN, NOTIFICATION_HEADER} from "../util/constants";
import {Link, withRouter} from "react-router-dom";
import {deleteDocument} from "../util/CandidateDocumentAPI";
import * as PropTypes from "prop-types";
import {API_BASE_URL, apiUrls, privateUrls} from "../route/Routes";

const { TextArea } = Input;

class StatusForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visibleStatusModal: false
    }
  }

  showStatusModal = () => {
    this.setState({
      visibleStatusModal: true
    });
  };

  okStatusModal = (e) => {
    e.preventDefault();
    const { inquiry, handleStatus, form : { getFieldValue } } = this.props;

    const payload = {
      id: inquiry.id,
      status: getFieldValue("status"),
      reason: getFieldValue("reason"),
    };

    updateInquiryStatus(payload)
      .then(() => handleStatus({ ...inquiry, ...payload }, true))
      .catch(() => handleStatus(inquiry, false));

    this.setState({
      visibleStatusModal: false
    });
  };

  cancelStatusModal = () => {
    this.setState({
      visibleStatusModal: false
    });
  };

  render() {
    const { form : { getFieldDecorator }, inquiry } = this.props;

    return (
      <Button.Group>
        <Button>{inquiry.status}</Button>
        <Button type="primary" ghost onClick={this.showStatusModal}><Icon type="edit" /></Button>
        <Modal
          title="Change Status"
          visible={this.state.visibleStatusModal}
          onOk={this.okStatusModal}
          onCancel={this.cancelStatusModal}
        >
          <Form onSubmit={this.okStatusModal}>
            <Form.Item label="Status">
              {getFieldDecorator('status', {initialValue: inquiry.status})(
                <Select>
                  <Select.Option value="NEW">NEW</Select.Option>
                  <Select.Option value="HIRED">HIRED</Select.Option>
                  <Select.Option value="TALENT POOL">TALENT POOL</Select.Option>
                  <Select.Option value="REJECTED">REJECTED</Select.Option>
                  <Select.Option value="DECLINED">DECLINED</Select.Option>
                  <Select.Option value="UNQUALIFIED">UNQUALIFIED</Select.Option>
                  <Select.Option value="PROCESSED">PROCESSED</Select.Option>
                </Select>
              )}
            </Form.Item>
            <Form.Item label="Reason" help="Please put your reason to move this job application.">
              {/* {getFieldDecorator('reason', {initialValue: inquiry.reason})( */}
              {getFieldDecorator('reason', {initialValue: inquiry.reason === null ? "" : inquiry.reason})(
                <TextArea rows={5} />
              )}
            </Form.Item>
          </Form>
        </Modal>
      </Button.Group>
    );
  }
}
StatusForm.propTypes = {
  inquiry: PropTypes.object.isRequired,
  handleStatus: PropTypes.func.isRequired
};
StatusForm = Form.create({name: 'status-form'})(StatusForm);

class ViewCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inquiry: {},
      isUploading: false
    };
  }

  loadCandidate = id => {
    getById(id)
      .then(response => {
        this.setState({
          inquiry: response.object
        });
      }).catch(error => {
        notification.error({
          message: NOTIFICATION_HEADER,
          description: error.message || 'Error! Cannot load candidate data at the moment. Please try again later.'
        });
      });
  };

  componentDidMount() {
    this.loadCandidate(this.props.match.params.id);
  }

  componentWillReceiveProps( nextProps, nextContext ) {
    if (nextProps.location.state === 'desiredState') {
      this.loadCandidate(nextProps.location.applicationId);
      this.props.history.push(nextProps.location.pathname);
      window.scrollTo(0, 0);
    }
  }

  exportFile = () => {
    const { inquiry } = this.state;
    const { vacancy = {}, candidate = {}, competencies = [], notes = [] } = inquiry;
    const { vacancyFunction = {} } = vacancy;

    const excelData =[
      [ "Name", "Email", "Phone", "Date Of Birth", "Nationality", "Current Salary", "Expected Salary" ],
    ];
    excelData.push(
      [
        inquiry.name,
        candidate.email,
        inquiry.phone,
        inquiry.dateOfBirth,
        inquiry.nationality,
        inquiry.currentSalary,
        inquiry.expectedSalary
      ]
    );
    excelData.push([]);
    excelData.push(["Position", "Job Function", "Apply Date", "Status"]);
    excelData.push(
      [
        vacancy.position,
        vacancyFunction.name,
        inquiry.applyDate,
        inquiry.status
      ]
    );
    excelData.push([]);
    excelData.push(["Competencies"]);
    excelData.push(["Name", "Level"]);
    for (let i = 0; i < competencies.length; i++) {
      excelData.push(
        [
          competencies[i].name,
          competencies[i].level
        ]
      );
    }
    excelData.push([]);
    excelData.push(["Notes"]);
    for (let i = 0; i < notes.length; i++) {
      excelData.push(
        [
          notes[i].note
        ]
      );
    }
		/* convert state to workbook */
		const ws = XLSX.utils.aoa_to_sheet(excelData);
		const wb = XLSX.utils.book_new();
		XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
		/* generate XLSX file and send to client */
		XLSX.writeFile(wb, "Candidate-" + inquiry.name + "-Vacancy-"+ vacancy.id + ".xlsx")
  };

  handleNoteCreate = event => {
    event.preventDefault();
    const { resetFields } = this.props.form;

    this.props.form.validateFieldsAndScroll((error, formInput) => {
      if (error) {
        return;
      }

      const postRequest = {
        inquiryId: this.state.inquiry.id,
        note: formInput.note,
      };

      createNote(postRequest)
        .then(response => {
          this.setState({
            inquiry: response.object
          });

          notification.success({
            message: NOTIFICATION_HEADER,
            description: "Success! A new note has been added.",
          });
          resetFields();
        }).catch(error => {
          notification.error({
            message: NOTIFICATION_HEADER,
            description: error.message || 'Error! Something went wrong. Please try again later.'
          });
          resetFields();
        });
    });
  };
  
  handleNoteEdit = (id, note) => {
    Modal.confirm({
      title : "Edit Note",
      content : <TextArea id="update-note-form" rows={5} defaultValue={note} />,
      okText : 'Save',
      onOk: () => {
        updateNote({id, note : document.getElementById("update-note-form").value})
          .then(response => {
            this.setState({inquiry: response.object});
            
            notification.success({
              message: NOTIFICATION_HEADER,
              description: "Success! The note has been updated.",
            });
          })
          .catch(() => {
            notification.error({
              message: NOTIFICATION_HEADER,
              description: "Error! Cannot perform note update at the moment.",
            });
          });
      }
    });
  };

  static convertRp = number => {
    if (number) {
      let	reverse = number.toString().split('').reverse().join(''),
      x = reverse.match(/\d{1,3}/g);

      return x.join('.').split('').reverse().join('');
    }

    return number;
  };

  handleDeleteDocument = inquiryId => {
    deleteDocument(inquiryId).then(() => {
      this.setState(state => ({
        inquiry: {
          ...state.inquiry,
          inquiryDocuments: state.inquiry.inquiryDocuments.filter(inquiry => inquiry.id !== inquiryId)
        }
      }));

      notification.success({
        message: NOTIFICATION_HEADER,
        description: "Success! The document has been deleted.",
      });
    });
  };

  handleStatusChange = (inquiry, success) => {
    if (success) {
      notification.success({
        message: NOTIFICATION_HEADER,
        description: "Success! The status has been changed.",
      });

      this.setState({ inquiry });
    } else {
      notification.error({
        message: NOTIFICATION_HEADER,
        description: "Error! Cannot perform status update at the moment. Please try again later.",
      });
    }
  };

  render() {
    const { inquiry = {} } = this.state;
    const { candidate = {}, vacancy = {}, relatedInquiries = [], competencies = [], inquiryDocuments = [], notes } = inquiry;
    const { vacancyFunction = {} } = vacancy;

    const data = [
      {
        title: 'Name',
        value: inquiry.name
      },
      {
        title: 'Email',
        value: candidate.email
      },
      {
        title: 'Phone',
        value: inquiry.phone
      },
      {
        title: 'Date Of Birth',
        value: inquiry.dateOfBirth
      },
      {
        title: 'Nationality',
        value: inquiry.nationality
      },
      {
        title: 'Current Salary',
        value: inquiry.currentSalary
      },
      {
        title: 'Expected Salary',
        value: inquiry.expectedSalary
      }
    ];

    const applicationData = [
      {
        title: 'ID',
        value: inquiry.id
      },
      {
        title: 'Position',
        value: vacancy.position
      },
      {
        title: 'Job Function',
        value: vacancyFunction.name
      },
      {
        title: 'Apply Date',
        value: inquiry.applyDate
      },
      {
        title: 'Status',
        value: <StatusForm inquiry={inquiry} handleStatus={this.handleStatusChange} />
      },
      {
        title: 'Reason',
        value: inquiry.reason
      }
    ];

    const uploadDocumentProps = {
      name: 'file',
      action: API_BASE_URL + apiUrls.inquiryAddDocument,
      accept: '.doc,.pdf,.rtf,.png,.jpg,.bmp',
      headers: {
        authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
      },
      data: {
        id: inquiry.id
      },
      showUploadList: false,
      onChange: info => {
        if (info.file.status === 'uploading') {
          this.setState({ isUploading: true });
          return;
        }

        if (info.file.status === 'done') {
          notification.success({
            message: NOTIFICATION_HEADER,
            description: `${info.file.name} file uploaded successfully.`,
          });

          this.setState({
            inquiry: {
              ...this.state.inquiry,
              inquiryDocuments: info.file.response.object.inquiryDocuments
            },
            isUploading: false
          });

          return;
        }

        if (info.file.status === 'error') {
          let d = [];
          let counter = 1;
          for (let [fileName, errorMessage] of Object.entries(info.file.response.messages)) {
            d.push(<p key={`${fileName}-${counter}`}> - {fileName} : {errorMessage}</p>);
            counter++;
          }
          notification.error({
            id: "apply-notification-error",
            message: NOTIFICATION_HEADER,
            description: <>{d}</>
          });
        } else {
          notification.error({
            id: "apply-notification-error",
            message: NOTIFICATION_HEADER,
            description: "Sorry! We cannot process your request at the moment. Please try again later.",
          });

        }

        this.setState({ isUploading: false });
      },
      beforeUpload: file => {
        this.setState({
          isUploading: true
        });
        const validFileTypes = ["image/png", "image/jpeg", "image/bmp", "application/pdf", "application/msword", "application/rtf"];
        const isValidFormat = validFileTypes.indexOf(file.type) > -1;

        if (!isValidFormat) {
          notification.error({
            message: NOTIFICATION_HEADER,
            description: 'Allowed file formats are: .doc, .pdf, .rtf, .png, .jpg, and .bmp.'
          });
        }

        const isValidSize = file.size / 1024 / 1024 < 5;

        if (!isValidSize) {
          notification.error({
            message: NOTIFICATION_HEADER,
            description: 'Maximum file size is 5MB.'
          });
        }

        return isValidFormat && isValidSize;
      }
    };

    const competencyColumns = [{
      title: 'Name',
      dataIndex: 'name'
    }, {
      title: 'Level',
      width: '15%',
      align: 'center',
      dataIndex: 'level'
    }];

    const { getFieldDecorator } = this.props.form;
    
    return (
      <div className="view-candidate-container">
        <h1><Icon type="profile" /> Job Application Detail</h1>
        <Button
            onClick={() => this.exportFile()}
            size="default">
            <Icon type="download" /> Download Excel
        </Button>
        <Divider><strong>APPLICATION DETAIL</strong></Divider>
        <List
          size="small"
          dataSource={applicationData}
          renderItem={item => (<List.Item><strong>{item.title + ': '}</strong>&nbsp;{item.value}</List.Item>)}
        />
        <Divider><strong>RELATED APPLICATIONS</strong></Divider>
        <div>
          {relatedInquiries.length === 0 ? <Empty /> : relatedInquiries.map(item =>
            <Link to={{pathname: `${privateUrls.inquiryList}/${item.id}`, applicationId: item.id, state: "desiredState"}} key={item.id}>
              <Button size="small">#{item.id}</Button>&nbsp;
            </Link>)}
        </div>
        <Divider><strong>CANDIDATE INFORMATION</strong></Divider>
        <List
          size="small"
          dataSource={data}
          renderItem={item => (<List.Item><strong>{item.title + ': '}</strong>&nbsp;{(item.title === "Current Salary" || item.title === 'Expected Salary') ? 'Rp ' + ViewCandidate.convertRp(item.value) : item.value}</List.Item>)}
        />
        <Divider><strong>COMPETENCIES</strong></Divider>
        <Table
          rowKey="id"
          bordered
          dataSource={competencies}
          columns={competencyColumns}
          pagination={false}
        />
        <Divider><strong>DOCUMENTS</strong></Divider>
        <div className="table-operations">
          <Upload {...uploadDocumentProps}>
            <Button loading={this.state.isUploading}>
              <Icon type="upload" /> Upload New Document
            </Button>
          </Upload>
        </div>
        <ListDocument candidateDocuments={inquiryDocuments} handleDelete={this.handleDeleteDocument} />
        <Divider><strong>NOTES</strong></Divider>
        <List
          dataSource={notes}
          renderItem={item => (
              <List.Item actions={[<Button type="link" onClick={() => this.handleNoteEdit(item.id, item.note)}>Edit</Button>]}>
              <List.Item.Meta
                avatar={<Avatar icon="user" />}
                title={item.updatedBy.name + " - " + moment(item.time).fromNow()}
                description={item.note}
              />
              <Form.Item>
                <TextArea rows={5} value={item.note} style={{display:'none'}}/>
              </Form.Item>
            </List.Item>
          )}
        />
        <Divider />
        <Form onSubmit={this.handleNoteCreate}>
          <Form.Item>
            {getFieldDecorator('note')(
              <TextArea rows={5} />
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">Add Note</Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create({ name: 'addNote' })(withRouter(ViewCandidate));