import {ACCESS_TOKEN} from './constants';
import {apiUrls} from "../route/Routes";
import {request} from "./BaseAPIRequest";

export const login = loginRequest => {
    return request({
        url: apiUrls.login,
        method: 'POST',
        body: JSON.stringify(loginRequest)
    });
};

export const getCurrentUser = () => {
    if (!localStorage.getItem(ACCESS_TOKEN)) {
        return Promise.reject("No access token set.");
    }

    return request({
        url: apiUrls.checkValidToken,
        method: 'GET'
    });
};