import React from 'react';
import './NotFound.css';
import {Link} from 'react-router-dom';
import {Button} from 'antd';

class NotFound extends React.Component {
  render() {
    const { homeUrl = "/"} = this.props;
    
    return (
      <div className="page-not-found">
          <h1 className="title">
              404
          </h1>
          <div className="desc">
              The Page you're looking for was not found.
          </div>
          <Link to={homeUrl}>
            <Button className="go-back-btn" type="primary" size="large">Go Back Home</Button>
          </Link>
      </div>
    );
  }
}

export default NotFound;