import {request} from "./BaseAPIRequest";
import {apiUrls} from "../route/Routes";

export const getVacancyFunctions = (options = {}) => {
  return request({
    url: apiUrls.vacancyFunction,
    method: 'GET',
    ...options
  });
};

export const getVacancyFunctionById = (id, options) => {
  return request({
    url: `${apiUrls.vacancyFunction}/${id}`,
    method: 'GET',
    ...options
  });
};

export const updateVacancyFunction = payload => {
  return request({
      url: apiUrls.vacancyFunction,
      method: 'PUT',
      body: JSON.stringify(payload)
  });
};

export const createVacancyFunction = payload => {
  return request({
      url: apiUrls.vacancyFunction,
      method: 'POST',
      body: JSON.stringify(payload)
  });
};
