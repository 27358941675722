import {apiUrls} from "../route/Routes";
import {request} from "./BaseAPIRequest";

export const getVacancies = (options = {}) => {
  return request({
    url: apiUrls.vacancy,
    method: 'GET',
    ...options
  });
};

export const getVacancyById = (id, options = {}) => {
  return request({
    url: `${apiUrls.vacancy}/${id}`,
    method: 'GET',
    ...options
  });
};

export const updateVacancy = payload => {
  return request({
      url: apiUrls.vacancy,
      method: 'PUT',
      body: JSON.stringify(payload)
  });
};

export const createVacancy = payload => {
  return request({
      url: apiUrls.vacancy,
      method: 'POST',
      body: JSON.stringify(payload)
  });
};

export const updateVacancyStatus = payload => {
  return request({
      url: apiUrls.vacancy,
      method: 'PATCH',
      body: JSON.stringify(payload)
  });
};