import React from 'react';
import { Button, Form, Input, Popconfirm, Select, Table, Row, Col } from 'antd';

class CompetencyForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dataSource: [],
      count: 0,
    };
  }

  handleDelete = (key) => {
    const dataSource = [...this.state.dataSource];
    this.setState({
      dataSource: dataSource.filter((item) => item.key !== key),
    });
  };

  handleAdd = () => {
    const { count, dataSource } = this.state;
    const newData = {
      key: count,
      name: '',
      level: 1,
    };
    this.setState({
      dataSource: [...dataSource, newData],
      count: count + 1,
    });
  };

  render() {
    const { dataSource } = this.state;
    const {
      form: { getFieldDecorator },
      formItemLayout = {},
    } = this.props;
    const columns = [
      {
        title: 'Name',
        dataIndex: 'name',
        render: (text, record) => (
          <Form.Item {...formItemLayout} hasFeedback>
            {getFieldDecorator(`competencies[${record.key}].name`, {
              rules: [
                { required: true, message: 'Competency name is required.' },
              ],
            })(<Input placeholder='Competency name' />)}
          </Form.Item>
        ),
      },
      {
        title: 'Level',
        width: '15%',
        dataIndex: 'level',
        render: (text, record) => (
          <Form.Item {...formItemLayout} hasFeedback>
            {getFieldDecorator(`competencies[${record.key}].level`, {
              rules: [
                { required: true, message: 'Competency level is required.' },
              ],
              initialValue: '1',
            })(
              <Select style={{ width: 'auto' }}>
                <Select.Option value='1'>1</Select.Option>
                <Select.Option value='2'>2</Select.Option>
                <Select.Option value='3'>3</Select.Option>
                <Select.Option value='4'>4</Select.Option>
                <Select.Option value='5'>5</Select.Option>
              </Select>
            )}
          </Form.Item>
        ),
      },
      {
        title: 'Action',
        width: '10%',
        dataIndex: 'action',
        render: (text, record) => (
          <Form.Item>
            <Popconfirm
              title='Sure to delete?'
              onConfirm={() => this.handleDelete(record.key)}
            >
              <Button type='danger' size='small' ghost>
                Delete
              </Button>
            </Popconfirm>
          </Form.Item>
        ),
      },
    ];

    return (
      <div style={{ marginBottom: '40px' }}>
        <Row>
          <Col sm={{ span: 16 }}>
            <h5>
              <strong>Competencies</strong>
            </h5>
          </Col>
          <Col sm={{ span: 8 }} type='flex' align='right'>
            <Button
              onClick={this.handleAdd}
              type='primary'
              ghost
              style={{ marginBottom: 16 }}
            >
              Add Competencies
            </Button>
          </Col>
        </Row>
        <Table
          bordered
          dataSource={dataSource}
          columns={columns}
          pagination={false}
          className='competencies-table'
        />
      </div>
    );
  }
}

export default CompetencyForm;
