import {apiUrls} from "../route/Routes";
import {request} from "../util/BaseAPIRequest";

export function getJobs(options) {
  return request({
      url: apiUrls.job,
      method: 'GET',
    ...options
  });
}

export function getJobById(id, options) {
  return request({
    url: `${apiUrls.job}/${id}`,
    method: 'GET',
    ...options
  });
}

export function applyJob(formInput, options = {}) {
  let payload = new FormData();
  appendFormData(payload, formInput);
  
  return request(
    {
    url: apiUrls.job,
    method: 'POST',
    body: payload,
    ...options
  }, false);
}

const appendFormData = (formData, data, parentKey) => {
  if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
    Object.keys(data).forEach(key => {
      appendFormData(formData, data[key], parentKey ? parentKey.hasOwnProperty(key) ? `${parentKey}[${key}]` : `${parentKey}.${key}` : key);
    });
  } else {
    const value = data == null ? '' : data;

    formData.append(parentKey, value);
  }
};