import React, { Component } from 'react';
import { Button, Icon, Modal, Popconfirm, Table } from 'antd';
import './ListDocument.css';

import FileViewer from 'react-file-viewer';
import {API_BASE_URL, apiUrls} from "../route/Routes";

class ListDocument extends Component {
  showModal = (id, fileName) => {
    const fileNameParts = fileName.split('.');
    const fileExt = fileNameParts[fileNameParts.length - 1].toLowerCase();
    Modal.info({
      title: 'File Preview',
      content: (
        <FileViewer
          fileType={fileExt}
          filePath={`${API_BASE_URL}${apiUrls.document}/${id}`}
        />
      ),
      icon: "file-search",
      width: "80%",
      maskClosable: true,
      okText: 'Close'
    });
  };

  render() {
    const { candidateDocuments = [] } = this.props;
    const data = [];

    candidateDocuments.forEach(document => {
      data.push(
        {
          key: document.id,
          fileName: document.fileName,
          vacancy: document.vacancy,
          allowDelete: document.updatedBy
        }
      );
    });

    const columns = [{
      title: 'Name',
      dataIndex: 'fileName',
      key: 'fileName',
      render: (text, record) => <Button onClick={() => this.showModal(record.key, text)}>{text}</Button>,
    }, {
      title: 'Vacancy',
      dataIndex: 'vacancy',
      key: 'vacancy'
    }, {
      title: 'Action',
      key: 'action',
      className: 'action',
      align: 'center',
      width: "15%",
      render: (text, record) => (
        <>
          <Button size="small" href={`${API_BASE_URL}${apiUrls.document}/${record.key}`}><Icon type="download"/></Button>
          {record.allowDelete &&
            <Popconfirm title="Sure to delete?" onConfirm={() => this.props.handleDelete(record.key)}>
              <Button type='danger' size='small' ghost style={{ marginLeft: "8px" }}><Icon type="delete"/></Button>
            </Popconfirm>
          }
        </>
      )
    }];

    return <Table columns={columns} dataSource={data} />;
  }
}

export default ListDocument;