import React from "react";
import { Redirect, Route, withRouter } from "react-router-dom";
import { privateUrls } from "../route/Routes";
import DashboardLayout from "../layouts/DashboardLayout";
import { connect } from "react-redux";
import { checkValidToken, doLogout } from "../store/actions";

const PrivateRouter = ({component: Component, isAuthenticated, ...rest}) => {
  return (
    <Route {...rest} render={props => isAuthenticated
      ? (
        <DashboardLayout {...{...rest, ...props}}>
          <Component {...{...rest, ...props}} />
        </DashboardLayout>
      )
      : (<Redirect to={{pathname: privateUrls.login, state: {from: props.location}}}/>)
    }/>
  );
};

const mapStateToProps = state => ({...state});
const mapDispatchToProps = dispatch => ({
  doLogout: () => dispatch(doLogout()),
  checkValidToken: () => dispatch(checkValidToken())
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PrivateRouter));