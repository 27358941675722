import React, { Component } from 'react';
import { Form, Input, Button, notification, Row, Col } from 'antd';
import './CreateFunction.css';
import {NOTIFICATION_HEADER} from '../util/constants';
import { createVacancyFunction } from '../util/VacancyFunctionAPI';
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router-dom';
import {privateUrls} from "../route/Routes";

class CreateFunction extends Component {
  handleSubmit = ( event ) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((error, formInput) => {
      if (error) {
        return;
      }

      createVacancyFunction(formInput)
        .then(() => {
          notification.success({
            message: NOTIFICATION_HEADER,
            description: "Success! A new job function has been created.",
          });
          
          this.props.history.push(`${privateUrls.vacancyList}#jobFunction`);
        }).catch(error => {
        notification.error({
          message: NOTIFICATION_HEADER,
          description: error.message || 'Sorry! Something went wrong. Please try again!'
        });
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="job-function-container">
        <h1>Create Job Function</h1>
        <Form onSubmit={this.handleSubmit} className="job-function-form">
          <Form.Item label="Name" hasFeedback>
            {getFieldDecorator('name', {
              rules: [{
                required: true,
                message: 'Job function name is required.'
              }],
            })(
              <Input size="large" placeholder="Please input job function name" />
            )}
          </Form.Item>
          <Form.Item label="Description" hasFeedback>
            {getFieldDecorator('description', {
              rules: [{
                required: true,
                message: 'Job function description is required.'
              }],
              getValueFromEvent: e => e && e.target ? e.target.getContent() : e
            })(
              <Editor
                init={{
                  toolbar: 'bold italic | alignleft aligncenter alignright',
                  menubar: false,
                  branding: false
                }}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Row>
              <Col span={8}>
                <Button type="primary" htmlType="submit" size="large" className="job-function-button">Save</Button>
              </Col>
              <Col span={8} offset={8}>
                <Link to={`${privateUrls.vacancyList}#jobFunction`}>
                  <Button className="job-function-button" size="large">Cancel</Button>
                </Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(CreateFunction);