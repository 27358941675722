import React, { Component } from 'react';
import {Form, Input, InputNumber, Button, notification, Row, Col, Divider, TimePicker, Select} from 'antd';
import './UpdateEmailTemplate.css';
import {NOTIFICATION_HEADER} from '../util/constants';
import { updateEmailTemplate, getEmailTemplateById } from '../util/EmailTemplateAPI'
import { Link } from 'react-router-dom';
import moment from 'moment';
import {TextEditor} from "../editor/TextEditor";
import {privateUrls} from "../route/Routes";

const timeFormat = 'HH:mm';

class UpdateEmailTemplate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emailTemplate: {},
    };
  }

  handleSubmit = event => {
    event.preventDefault();
    
    this.props.form.validateFieldsAndScroll((error, formInput) => {
      if (error) {
        return;
      }
  
      formInput.id = this.state.emailTemplate.id;
      formInput.sendAtTime = formInput.sendAtTime.format(timeFormat);
      
      updateEmailTemplate(formInput)
        .then(() => {
          notification.success({
            message: NOTIFICATION_HEADER,
            description: "Success! The email template has been successfully updated.",
          });
          this.props.history.push(privateUrls.emailTemplateList);
        }).catch(error => {
          notification.error({
            message: NOTIFICATION_HEADER,
            description: error.message || 'Error! Cannot update email template at the moment. Please try again later.'
          });
        });
    });
  };

  componentDidMount() {
    getEmailTemplateById(this.props.match.params.id)
      .then(response => {
        this.setState({
          emailTemplate: response.object
        });
      }).catch(error => {
        notification.error({
          message: NOTIFICATION_HEADER,
          description: error.message || 'Sorry! Cannot load email template at the moment. Please try again later.'
        });
      });
  }

  render() {
    const { emailTemplate } = this.state;
    const { getFieldDecorator } = this.props.form;
    
    return (
      <div className="update-template-container">
        <h1>Update Email Template</h1>
        <Form onSubmit={this.handleSubmit} className="update-template-form">
          <Form.Item
            label="Recipient"
            hasFeedback
            extra={<>You can use <code>[candidateEmail]</code> to automatically set recipient to candidate email.</>}
          >
            {getFieldDecorator('recipient', {
              rules: [{
                required: true,
                message: 'Email template recipient is required.',
              }, {
                type:'email',
                pattern: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
                message: 'Please provide valid email address.',
                transform: value => value === '[candidateEmail]' ? 'valid.email@mitrais.com' : value
              }],
              initialValue: emailTemplate.recipient
            })(
              <Input size="large" placeholder="Please input email template recipient"/>
            )}
          </Form.Item>
          <Form.Item label="Sender" hasFeedback>
            {getFieldDecorator('sender', {
              rules: [{
                type:'email',
                required: true,
                message: 'Email template sender is required.',
              }],
              initialValue: emailTemplate.sender
            })(
              <Input size="large" placeholder="Please input email template sender"/>
            )}
          </Form.Item>
          <Form.Item label="Subject" hasFeedback>
            {getFieldDecorator('subject', {
              rules: [{
                required: true,
                message: 'Email template subject is required.',
              }],
              initialValue: emailTemplate.subject
            })(
              <Input size="large" placeholder="Please input email template subject" />
            )}
          </Form.Item>
          <Form.Item
            label="Content"
            extra={
              <>
                The following keywords will be turned into Candidate Name, Position Name, Apply Date, Application ID, and Application Status respectively :<br />
                <code>[candidateName]</code>, <code>[positionName]</code>, <code>[applyDate]</code>, <code>[applicationId]</code>, and <code>[applicationStatus]</code>
              </>
            }
          >
            {getFieldDecorator('content', {
              rules: [{
                required: true,
                message: 'Email template content is required.',
              }],
              initialValue: emailTemplate.content,
              trigger: 'onEditorChange',
              getValueFromEvent: e => e && e.target ? e.target.getContent() : e
            })(
              <TextEditor
                addToToolbar={["candidateButton email phone dob nationality positionButton", 
                  "applyDateButton applicationIdButton statusButton currentSalary expectedSalary cadidateCV",
                  "utmSource utmCampaign utmMedium utmTerm utmContent originReferrer"]}
                addToInit={{
                  setup: editor => {
                    editor.addButton('candidateButton', {
                      text: '[candidateName]',
                      onclick: () => editor.insertContent('[candidateName]')
                    });
                    editor.addButton('email', {
                      text: '[candidateEmail]',
                      onclick: () => editor.insertContent('[candidateEmail]')
                    });
                    editor.addButton('phone', {
                      text: '[candidatePhone]',
                      onclick: () => editor.insertContent('[candidatePhone]')
                    });
                    editor.addButton('dob', {
                      text: '[candidateDOB]',
                      onclick: () => editor.insertContent('[candidateDOB]')
                    });
                    editor.addButton('nationality', {
                      text: '[Nationality]',
                      onclick: () => editor.insertContent('[candidateNationality]')
                    });
                    editor.addButton('positionButton', {
                      text: '[positionName]',
                      onclick: () => editor.insertContent('[positionName]')
                    });
                    editor.addButton('applyDateButton', {
                      text: '[applyDate]',
                      onclick: () => editor.insertContent('[applyDate]')
                    });
                    editor.addButton('applicationIdButton', {
                      text: '[applicationId]',
                      onclick: () => editor.insertContent('[applicationId]')
                    });
                    editor.addButton('statusButton', {
                      text: '[applicationStatus]',
                      onclick: () => editor.insertContent('[applicationStatus]')
                    });
                    editor.addButton('currentSalary', {
                      text: '[currentSalary]',
                      onclick: () => editor.insertContent('[currentSalary]')
                    });
                    editor.addButton('expectedSalary', {
                      text: '[ExpectedSalary]',
                      onclick: () => editor.insertContent('[expectedSalary]')
                    });
                    editor.addButton('cadidateCV', {
                      text: '[candidateCv]',
                      onclick: () => editor.insertContent('[candidateCv]')
                    });
                    editor.addButton('utmSource', {
                      text: '[utmSource]',
                      onclick: () => editor.insertContent('[utmSource]')
                    });
                    editor.addButton('utmCampaign', {
                      text: '[utmCampaign]',
                      onclick: () => editor.insertContent('[utmCampaign]')
                    });
                    editor.addButton('utmMedium', {
                      text: '[utmMedium]',
                      onclick: () => editor.insertContent('[utmMedium]')
                    });
                    editor.addButton('utmTerm', {
                      text: '[utmTerm]',
                      onclick: () => editor.insertContent('[utmTerm]')
                    });
                    editor.addButton('utmContent', {
                      text: '[utmContent]',
                      onclick: () => editor.insertContent('[utmContent]')
                    });
                    editor.addButton('originReferrer', {
                      text: '[originReferrer]',
                      onclick: () => editor.insertContent('[originReferrer]')
                    });
                  }
                }}
              />
            )}
          </Form.Item>
          <Row>
            <Col span={6}>
              <Form.Item label="Days to Send" hasFeedback>
                {getFieldDecorator('daysToSend', {
                  rules: [{
                    required: true,
                    message: 'Please specify how many days to send this email template after job application status changed.',
                  }, {
                    type: 'number',
                    message: 'Days to send value must be a number.'
                  }],
                  initialValue: emailTemplate.daysToSend
                })(
                  <InputNumber size="large" autoComplete="off" min={0} />
                )}
              </Form.Item>
            </Col>
            <Col span={6} offset={6}>
              <Form.Item label="Send at Time" hasFeedback>
                {getFieldDecorator('sendAtTime', {
                  rules: [{
                    pattern: /^([0-1][0-9]|2[0-3]):([0-5][0-9])$/,
                    required: true,
                    message: 'Please specify at what time this email template should be send.',
                    transform: value => value.format(timeFormat)
                  }],
                  initialValue: emailTemplate.sendAtTime ? moment(emailTemplate.sendAtTime, timeFormat) : moment('07:00', timeFormat)
                })(
                  <TimePicker format={timeFormat} size="large" autoComplete="off"/>
                )}
              </Form.Item>
            </Col>
          </Row>
          <Form.Item label="Active" hasFeedback>
            {getFieldDecorator('isActive', {
              rules: [{
                required: true, message: 'Please specify whether this template is active.',
              }],
              initialValue: emailTemplate.isActive === 1 ? "1" : "0"
            })(
              <Select size="large">
                <Select.Option value="1">Active</Select.Option>
                <Select.Option value="0">Inactive</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Divider />
          <Form.Item>
            <Row>
              <Col span={8}>
                <Button type="primary" htmlType="submit" size="large" className="create-template-form-button">Save</Button>
              </Col>
              <Col span={4} offset={8}>
                <Link  to={privateUrls.emailTemplateList}>
                  <Button className="create-template-form-button" size="large">Cancel</Button>
                </Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(UpdateEmailTemplate);