import React, { Component } from 'react';
import { Form, Input, Button, notification, Select, Row, Col, Divider, Checkbox } from 'antd';
import './CreateUser.css';
import {
  NAME_MIN_LENGTH, NAME_MAX_LENGTH,
  USERNAME_MIN_LENGTH, USERNAME_MAX_LENGTH,
  PASSWORD_MIN_LENGTH, PASSWORD_MAX_LENGTH,
  NOTIFICATION_HEADER
} from '../util/constants';
import { createUser, checkUsernameAvailability } from '../util/UserAPI';
import { Link } from 'react-router-dom';
import {privateUrls} from "../route/Routes";

const FormItem = Form.Item;
const Option = Select.Option;

class CreateUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: {
        value: ''
      },
      username: {
        value: ''
      },
      password: {
        value: ''
      },
      email: {
        value: ''
      },
      active: {
        value: 'active',
        validateStatus: 'success'
      },
      setting: {
        emailInquiryNew: '0',
        emailInquiryHired: '0',
        emailInquiryTalentPool: '0',
        emailInquiryDeclined: '0',
        emailInquiryRejected: '0'
      }
    };
  }


  handleSubmit = event => {
    event.preventDefault();
    const createRequest = {
      name: this.state.name.value,
      username: this.state.username.value,
      password: this.state.password.value,
      email: this.props.form.getFieldValue('email'),
      active: this.state.active.value === "active" ? 1 : 0,
      setting: this.state.setting
    };
    
    createUser(createRequest)
      .then(() => {
        notification.success({
          message: NOTIFICATION_HEADER,
          description: "Success! A new user has been created.",
        });
        
        this.props.history.push(privateUrls.userList);
      }).catch(error => {
        notification.error({
          message: NOTIFICATION_HEADER,
          description: error.message || 'Sorry! Something went wrong. Please try again!'
        });
      });
  };

  handleInputChange = (event, validationFun) => {
    const target = event.target;
    const inputName = target.name;
    const inputValue = target.value;

    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue)
      }
    });
  };

  handleSelectChange = (value, validationFun) => {
    const inputName = "active";
    const inputValue = value;
    this.setState({
      [inputName]: {
        value: inputValue,
        ...validationFun(inputValue)
      }
    });
  };

  isFormInvalid = () => {
    return !(this.state.name.validateStatus === 'success' &&
      this.state.username.validateStatus === 'success' &&
      this.state.password.validateStatus === 'success' &&
      this.state.active.validateStatus === 'success'
    );
  };

  handleEmailSetting = event => {
    const inputName = event.target.name;
    const isChecked = event.target.checked;

    this.setState({
      setting: {
        ...this.state.setting,
        [inputName]: isChecked ? "1" : "0",
      }
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="create-user-container">
        <h1>Create User</h1>
        <Form onSubmit={this.handleSubmit} className="user-form">
          <FormItem 
              label="Name"
              hasFeedback
              required
              validateStatus={this.state.name.validateStatus}
              help={this.state.name.errorMsg}>
              <Input 
                  size="large"
                  name="name"
                  autoComplete="off"
                  placeholder="Please input name of user"
                  value={this.state.name.value} 
                  onChange={(event) => this.handleInputChange(event, this.validateName)} />    
          </FormItem>
          <FormItem 
              label="Username"
              hasFeedback
              required
              validateStatus={this.state.username.validateStatus}
              help={this.state.username.errorMsg}>
              <Input 
                  size="large"
                  name="username" 
                  autoComplete="off"
                  placeholder="Please input username of user"
                  value={this.state.username.value} 
                  onBlur={this.validateUsernameAvailability}
                  onChange={(event) => this.handleInputChange(event, this.validateUsername)} />    
          </FormItem>
          <FormItem 
              label="Password"
              hasFeedback
              required
              validateStatus={this.state.password.validateStatus}
              help={this.state.password.errorMsg}>
              <Input 
                  size="large"
                  name="password" 
                  type="password"
                  autoComplete="off"
                  placeholder="A password between 6 to 20 characters" 
                  value={this.state.password.value} 
                  onChange={(event) => this.handleInputChange(event, this.validatePassword)} />    
          </FormItem>
          <FormItem
            label="Email"
            validateStatus={this.state.email.validateStatus}
            help={this.state.email.errorMsg}
            hasFeedback
          >
            {getFieldDecorator('email', {
              rules: [{
                type: 'email', message: 'The input is not a valid E-mail!',
              }, {
                required: true, message: 'Please input your E-mail!',
              }],
              initialValue: this.state.email.value
            })(
              <Input size="large" autoComplete="off" placeholder="Please enter a valid email address"/>
            )}
          </FormItem>
          <FormItem 
              label="Active"
              hasFeedback
              required
              validateStatus={this.state.active.validateStatus}
              help={this.state.active.errorMsg}>
              <Select 
                defaultValue="" 
                size="large"
                name="active"
                value={this.state.active.value} 
                onChange={(event) => this.handleSelectChange(event, this.validateActive)} > 
                  <Option value=""> - Choose - </Option>
                  <Option value="active">Active</Option>
                  <Option value="deactive">Deactive</Option>
              </Select>
          </FormItem>
          <Divider />
          <FormItem>
            Select options below to receive email notification :
            <Row>
              <Col span={24}>
                <Checkbox name="emailInquiryNew" onChange={this.handleEmailSetting}>when a NEW job application is submitted.</Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox name="emailInquiryHired" onChange={this.handleEmailSetting}>when a job application status is changed to HIRED.</Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox name="emailInquiryTalentPool" onChange={this.handleEmailSetting}>when a job application status is changed to TALENT POOL.</Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox name="emailInquiryDeclined"  onChange={this.handleEmailSetting}>when a job application status is changed to DECLINED.</Checkbox>
              </Col>
              <Col span={24}>
                <Checkbox name="emailInquiryRejected" onChange={this.handleEmailSetting}>when a job application status is changed to REJECTED.</Checkbox>
              </Col>
            </Row>
          </FormItem>
          <Divider />
          <FormItem>
            <Row>
              <Col span={8}>
              <Button type="primary" 
                  htmlType="submit" 
                  size="large" 
                  className="create-user-form-button"
                  disabled={this.isFormInvalid()}>Save</Button>
              </Col>
              <Col span={8} offset={8}>
                <Link  to={privateUrls.userList}>
                  <Button className="create-user-form-button" size="large">Cancel</Button>
                </Link>
              </Col>
            </Row>
          </FormItem>
        </Form>
      </div>
    );
  }

  validateName = (name) => {
    if (name.length < NAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Name is too short (Minimum ${NAME_MIN_LENGTH} characters needed.)`
      }
    } else if (name.length > NAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Name is too long (Maximum ${NAME_MAX_LENGTH} characters allowed.)`
      }
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
  };

  validateUsername = (username) => {
    if (username.length < USERNAME_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Username is too short (Minimum ${USERNAME_MIN_LENGTH} characters needed.)`
      }
    } else if (username.length > USERNAME_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Username is too long (Maximum ${USERNAME_MAX_LENGTH} characters allowed.)`
      }
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
  };

  validatePassword = (password) => {
    if (password.length < PASSWORD_MIN_LENGTH) {
      return {
        validateStatus: 'error',
        errorMsg: `Password is too short (Minimum ${PASSWORD_MIN_LENGTH} characters needed.)`
      }
    } else if (password.length > PASSWORD_MAX_LENGTH) {
      return {
        validationStatus: 'error',
        errorMsg: `Password is too long (Maximum ${PASSWORD_MAX_LENGTH} characters allowed.)`
      }
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
  };

  validateActive = (active) => {
    if (active === '') {
      return {
        validateStatus: 'error',
        errorMsg: `The active should be Active or Deactive`
      }
    } else {
      return {
        validateStatus: 'success',
        errorMsg: null,
      };
    }
  };

  validateUsernameAvailability = () => {
    // First check for client side errors in username
    const usernameValue = this.state.username.value;
    const usernameValidation = this.validateUsername(usernameValue);

    if (usernameValidation.validateStatus === 'error') {
      this.setState({
        username: {
          value: usernameValue,
          ...usernameValidation
        }
      });
      return;
    }

    this.setState({
      username: {
        value: usernameValue,
        validateStatus: 'validating',
        errorMsg: null
      }
    });

    checkUsernameAvailability(usernameValue)
      .then(response => {
        if (response.available) {
          this.setState({
            username: {
              value: usernameValue,
              validateStatus: 'success',
              errorMsg: null
            }
          });
        } else {
          this.setState({
            username: {
              value: usernameValue,
              validateStatus: 'error',
              errorMsg: 'This username is already taken'
            }
          });
        }
      }).catch(() => {
        this.setState({
          username: {
            value: usernameValue,
            validateStatus: 'success',
            errorMsg: null
          }
        });
      });
  }
}

export default Form.create({ name: 'createUser' })(CreateUser);