import {ACCESS_TOKEN} from "../util/constants";
import {getCurrentUser} from "../util/AuthAPI";

/**
 * Action types
 */

export const LOGIN = "LOGIN";
export const LOGOUT = "LOGOUT";

/**
 * Action creators
 */

export const doLogin = (payload) => {
  localStorage.setItem(ACCESS_TOKEN, payload.accessToken);
  return { type: LOGIN, currentUser: {username: payload.username, name: payload.name} }
};

export const doLogout = () => {
  localStorage.removeItem(ACCESS_TOKEN);
  return { type: LOGOUT }
};

export const checkValidToken = () => {
  return dispatch => getCurrentUser()
    .then(response => dispatch({ type: LOGIN, currentUser: {username: response.username, name: response.name} }))
    .catch(() => dispatch(doLogout()));
};