import React from 'react';
import ScrollIntoView from 'react-scroll-into-view';
import { Link } from 'react-router-dom';
import { Form, Table, Col, Row, Layout, Alert, Button } from 'antd';
import { getJobs } from './JobAPI';
import JobHeader from './JobHeader';
import './Job.css';
import './JobResponsive.css';
import TinySlider from 'tiny-slider-react';
import 'tiny-slider/dist/tiny-slider.css';
import { testimonials, benefits, warningDescription } from './JobConstants';
import iconArrowLeftNav from '../assets/ico-arrow-left-nav.svg';
import iconArrowRightNav from '../assets/ico-arrow-right-nav.svg';

class Jobs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      jobs: [],
      jobLocations: [],
      jobFunctions: [],
      filteredJobs: [],
      isLoadingData: true,
    };
    this.tinySlider = React.createRef();
  }

  mController = new AbortController();
  mSignal = this.mController.signal;

  tablePaginationRender = (page, type, originalElement) => {
    const paginateStyle = {
      border: '1px solid #dee2e6',
      minWidth: '36px',
      height: '39px',
      lineHeight: '39px',
      padding: '0 14px',
    };

    if (type === 'prev') return <div style={paginateStyle}>Previous</div>;
    else if (type === 'next') return <div style={paginateStyle}>Next</div>;
    else return originalElement;
  };

  componentDidMount = async () => {
    await getJobs({ signal: this.mSignal })
      .then((response) => {
        this.setState({
          jobs: response,
          filteredJobs: response,
          jobLocations: [...new Set(response.map((job) => job.location))],
          jobFunctions: [
            ...new Set(response.map((job) => job.vacancyFunction.name)),
          ],
          isLoadingData: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoadingData: false,
        });
      });
  };

  componentWillUnmount() {
    this.mController.abort();
  }

  handleFilterSubmit = (e) => {
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((error, formInput) => {
      if (error) {
        return;
      }

      let { keyword, location, jobFunction } = formInput;
      let { jobs } = this.state;

      if (location) {
        jobs = jobs.filter((job) =>
          job.location.toString().toLowerCase().includes(location.toLowerCase())
        );
      }

      if (jobFunction) {
        jobs = jobs.filter((job) =>
          job.vacancyFunction.name
            .toString()
            .toLowerCase()
            .includes(jobFunction.toLowerCase())
        );
      }

      this.setState({
        filteredJobs: jobs.filter((job) => {
          return !keyword
            ? job
            : job.position
                .toString()
                .toLowerCase()
                .includes(keyword.toLowerCase()) ||
                job.location
                  .toString()
                  .toLowerCase()
                  .includes(keyword.toLowerCase()) ||
                job.vacancyFunction.name
                  .toString()
                  .toLowerCase()
                  .includes(keyword.toLowerCase());
        }),
      });
    });
  };

  render() {
    const { getFieldDecorator } = this.props.form;
    const { isLoadingData, jobLocations, jobFunctions, filteredJobs } =
      this.state;

    const bannerContent = () => {
      return (
        <>
          <div className='title'>Career at Mitrais &#10084;</div>
          <h1>Work where you want and earn what you deserve </h1>
          <div className='mt-2'>
            <ScrollIntoView selector='#jobs-opening'>
              <a className='theme-btn btn-style-one'>View Openings {'->'}</a>
            </ScrollIntoView>
          </div>
          <div className='gptw mt-4'>
            <div className='description'>
              <p>Join us and be part of</p>
              <h4>Mitrais</h4>
            </div>
          </div>
        </>
      );
    };

    const onGoTo = direction => {
      this.tinySlider.slider.goTo(direction);
      this.tinySlider.slider.pause();
    };

    const settings = {
      autoplay: true,
      autoplayHoverPause: true,
      autoplayButtonOutput: false,
      lazyload: true,
      nav: false,
      mouseDrag: true,
      items: 1,
      controls: false,
      touch: true,
      loop: true,
      gutter: 30,
      responsive: {
        992: {
          items: 2,
        },
      },
    };

    const columns = [
      {
        title: 'No.',
        dataIndex: 'orderNo',
        key: 'orderNo',
        align: 'center',
        width: 65,
      },
      {
        title: 'Position',
        dataIndex: 'position',
        key: 'position',
        render: (text, job) => (
          <div>
            <p style={{ color: '#212529' }}>{text.split('|')[0]}</p>
            <p style={{ color: '#00000080', fontSize: 12.25 }}>
              {text.split('|')[1]}
            </p>
          </div>
        ),
      },
      {
        dataIndex: 'apply',
        key: 'apply',
        align: 'center',
        width: '12%',
        render: (text, job) => (
          <Link to={`/jobs/${job.key}`}>
            <p style={{ color: '#6200c0' }}>
              {text}
              <span className='arrow-desktop'>{'->'}</span>
              <img
                alt='right-arrow'
                className='arrow-icon text-center'
                src={require(`../assets/icons/arrow-right.svg`)}
              ></img>
            </p>
          </Link>
        ),
      },
    ];

    const data = [];

    filteredJobs.forEach((job, index) => {
      let orderNo = index + 1;

      data.push({
        key: job.id,
        orderNo,
        position: `${job.position}|${job.location}`,
        jobFunction: job.vacancyFunction.name,
        location: job.location,
        apply: 'Apply',
      });
    });

    return (
      <>
        <Layout className='page-wrapper job-section'>
          <JobHeader
            content={bannerContent()}
            bannerBackground={require('../assets/header_bg.jpg')}
          ></JobHeader>
          <Layout.Content className='about-section'>
            <div className='auto-container'>
              <div className='section-year-title' style={{ display: 'none' }}>
                est. 1991
              </div>
              <Row>
                <Col lg={{ span: 24 }} md={{ span: 24 }} sm={{ span: 24 }}>
                  <div className='sec-title'>
                    <div className='title'>About</div>
                    <h2>
                      Over 30 years of experience leading and developing in
                      software development.{' '}
                    </h2>
                  </div>
                  <p>
                    Continues commitment has always been our mantra in providing
                    valuable services to our clients. We aim to deliver
                    outstanding value for clients who have a long-term vision
                    for their products.
                    <br /> <br />
                    Our offshoring solutions development model combines highly
                    experienced project managers with decades of experience
                    team. The combination of sustainable talent, proper
                    planning, and proven process that delivers exceptional
                    results is one of the reasons for the long-term commitment
                    of our clients.{' '}
                  </p>
                  <a
                    href='https://www.mitrais.com/about-us/'
                    className='theme-btn btn-style-one mb-4'
                  >
                    <span className='txt'>More About Us</span>
                  </a>
                </Col>
              </Row>
            </div>
          </Layout.Content>
          <Layout.Content className='fluid-section-one searvice-section'>
            <div className='auto-container'>
              <Row>
                <Col lg={{ span: 24 }}>
                  <div className='sec-title'>
                    <div className='title'>Our Values</div>
                    <h2>Apply Now To Join Mitrais</h2>
                    <p>
                      Find out why at{' '}
                      <a href='https://www.instagram.com/explore/tags/mitraislife/'>
                        #MitraisLife
                      </a>
                    </p>
                    <p>
                      In 2021, over 170 new talented team members joined
                      Mitrais. When we asked what brought them here, this is
                      what they said:
                    </p>
                  </div>
                  <Row gutter={48} className='benefits'>
                    {benefits.map((value, index) => (
                      <Col
                        key={index}
                        lg={{ span: 8 }}
                        md={{ span: 12 }}
                        sm={{ span: 24 }}
                        style={{ minHeight: '250px' }}
                      >
                        <div>
                          <img
                            alt='icon'
                            src={require(`../assets/icons/${value.icon}.svg`)}
                          ></img>
                          <h4>{value.title}</h4>
                          <p>{value.description}</p>
                        </div>
                      </Col>
                    ))}
                  </Row>
                </Col>
              </Row>
            </div>
          </Layout.Content>

          {/* Testimonials */}
          <Layout.Content className='testimonial-section style-two'>
            <div className='auto-container'>
              <div className='sec-title'>
                <h2>What do They Say?</h2>
              </div>
              <div className="testimonial-navigation">
                <img 
                  src={iconArrowRightNav} 
                  alt="icon arrow right navigation"
                  className="pull-right"
                  onClick={() => onGoTo('next')}
                />
                <img
                  src={iconArrowLeftNav} 
                  alt="icon arrow left navigation"
                  className="pull-right"
                  onClick={() => onGoTo('prev')}
                />
              </div>
              <TinySlider settings={settings} ref={ts => this.tinySlider = ts}>
                {testimonials.map((testimonial, index) => (
                  <div key={index} className='testimonial-block'>
                    <div className='inner-box'>
                      <div className='quote icon_quotations'>
                        <img
                          src={require(`../assets/${testimonial.image}.jpg`)}
                          className='img-circle'
                          alt='testimonial'
                        />
                      </div>
                      <div className="testimonial-content-container">
                        {/* Maximum characters for testimonial.content is 368 including space */}
                        <p className='text-center'>{testimonial.content}</p>
                      </div>
                      <div className='author text-center mt-2'>
                        {/* Maximum characters for testimonial.name and testimonial.role is 33 including space */}
                        {testimonial.name} <span>/ {testimonial.role}</span>
                      </div>
                    </div>
                  </div>
                ))}
              </TinySlider>
            </div>
          </Layout.Content>
          {/* Job Openings  */}

          <Layout.Content className='jobs' id='jobs-opening'>
            <div className='auto-container'>
              <div className='sec-title'>
                <h3>Our Vacancies</h3>
              </div>
              <Form onSubmit={this.handleFilterSubmit}>
                <div className='find-job'>
                  <Row gutter={24}>
                    <Col lg={{ span: 6 }}>
                      <div className='form-floating'>
                        {getFieldDecorator('location', {
                          initialValue: '',
                        })(
                          <select
                            className='form-select'
                            id='floatingSelect'
                            aria-labelledby='Select Location'
                          >
                            <option value=''>All</option>
                            {jobLocations.map((location) => (
                              <option key={location} value={location}>
                                {location}
                              </option>
                            ))}
                          </select>
                        )}

                        <label htmlFor='floatingSelect'>Location</label>
                      </div>
                    </Col>
                    <Col lg={{ span: 6 }}>
                      <div className='form-floating'>
                        {getFieldDecorator('jobFunction', {
                          initialValue: '',
                        })(
                          <select
                            className='form-select'
                            id='floatingSelect'
                            aria-labelledby='Select Location'
                          >
                            <option value=''>Select Job Function</option>
                            {jobFunctions.map((job) => (
                              <option key={job} value={job}>
                                {job}
                              </option>
                            ))}
                          </select>
                        )}
                        <label htmlFor='floatingSelect'>Job Function</label>
                      </div>
                    </Col>
                    <Col lg={{ span: 6 }}>
                      <div className='form-floating'>
                        {getFieldDecorator('keyword', { initialValue: '' })(
                          <input type='text' className='form-control' />
                        )}
                        <label htmlFor='floatingInput'>Keyword</label>
                      </div>
                    </Col>
                    <Col lg={{ span: 6 }}>
                      <div className=''>
                        <Button
                          type='primary'
                          htmlType='submit'
                          size='large'
                          style={{
                            width: '100%',
                            height: '3.5rem',
                          }}
                        >
                          Search
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </div>
              </Form>
            </div>
            <div className='table-container'>
              <Table
                columns={columns}
                dataSource={data}
                pagination={{
                  defaultPageSize: 10,
                  itemRender: this.tablePaginationRender,
                }}
                style={{ marginBottom: '40px' }}
                loading={isLoadingData}
                className='vacancy-table'
              />
            </div>
          </Layout.Content>

          <Layout.Content className='process py-5 my-5'>
            <div className='auto-container'>
              <Row>
                <Col lg={{ span: 24 }}>
                  <div
                    style={{
                      textAlign: 'center',
                    }}
                  >
                    <h4 className='mb-2'>Mitrais Recruitment Process</h4>
                    <img
                      src={require('../assets/ic-more-combine.png')}
                      className='mb-3 d-none d-md-block recruitment-desktop'
                      alt='recruitment'
                    />
                    <img
                      src={require('../assets/hiring-process-mobile.jpg')}
                      alt=''
                      style={{ display: 'none', margin: 'auto' }}
                      className='recruitment-mobile'
                    />
                    <p>
                      Mitrais does not only conduct Equal Employment Opportunity and Anti-Modern Slavery
                      but also strives to provide a pleasant recruitment
                      experience. Mitrais’ Recruiter will be more than happy to
                      guide Candidates through each stage of the process and to
                      answer any inquires that might arise during the process.
                    </p>
                  </div>
                </Col>
                <Col lg={{ span: 24 }}>
                  <div
                    style={{
                      textAlign: 'center',
                      marginBottom: '5rem',
                      marginTop: '1.5rem',
                    }}
                  >
                    {' '} 
                    <Alert 
                      showIcon 
                      icon={
                        <img 
                          src={require('../assets/warning-gr-256-2.png')}
                          className='ant-alert-warning-icon'
                        />
                      } 
                      description={warningDescription} 
                      message='Please note that all activities included in the recruitment processes are free of charge.'
                      type='warning' 
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </Layout.Content>
        </Layout>
      </>
    );
  }
}

export default Form.create({ name: 'filterJobs' })(Jobs);
