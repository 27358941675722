import { ACCESS_TOKEN} from './constants';
import {API_BASE_URL} from "../route/Routes";

export function deleteDocument(id, options) {
  return fetch(API_BASE_URL + "/document/" + id, {
    headers: {
      authorization: 'Bearer ' + localStorage.getItem(ACCESS_TOKEN)
    },
    method: 'DELETE',
    ...options
  }).then(response =>
    response.json().then(json => {
      if (!response.ok) {
        return Promise.reject(json);
      }

      return json;
    })
  ).catch( () => {});
}