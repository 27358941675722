import AppHeader from "../common/AppHeader";
import {Dropdown, Icon, Layout, Menu} from "antd";
import React from "react";
import {privateUrls} from "../route/Routes";
import {Link} from "react-router-dom";

class DashboardLayout extends React.Component {
  handleMenu = ({ item, key, keyPath, domEvent }) => {
    if (key === "logout") {
      this.props.doLogout();
      this.props.history.push(privateUrls.login);
      return;
    }
    
    this.props.checkValidToken();
  };
  
  render() {
    const { children, currentUser, location = {} } = this.props;
    
    const menuItems = [
      {
        url: privateUrls.dashboard,
        title: "Dashboard",
        icon: "home"
      },
      {
        url: privateUrls.vacancyList,
        title: "Vacancy",
        icon: "profile"
      },
      {
        url: privateUrls.inquiryList,
        title: "Job Application",
        icon: "team"
      },
      {
        url: privateUrls.userList,
        title: "User",
        icon: "user"
      },
      {
        url: privateUrls.emailTemplateList,
        title: "Email Template",
        icon: "mail"
      },
      {
        url: "/profile",
        title: "Profile",
        icon: "setting",
        dropDown:
          <Menu onClick={this.handleMenu} className="profile-dropdown-menu">
            <Menu.Item key="user-info" className="dropdown-item" disabled>
              <div className="user-full-name-info">
                {currentUser.name}
              </div>
              <div className="username-info">
                @{currentUser.username}
              </div>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="logout" className="dropdown-item">
              Logout
            </Menu.Item>
          </Menu>
      }
    ];
    
    return (
      <Layout className="app-container">
        <AppHeader homeUrl={privateUrls.basePath}>
          <Menu className="app-menu" onClick={this.handleMenu} mode="horizontal" selectedKeys={[location.pathname]}>
            {menuItems.map((menuItem, index) => {
              if (menuItem.dropDown) {
                return (
                  <Menu.Item key={menuItem.url} title={menuItem.title} className={`dropdown-${index}`}>
                    <Dropdown
                      overlay={menuItem.dropDown}
                      trigger={['click']}
                      getPopupContainer = {() => document.getElementsByClassName(`dropdown-${index}`)[0]}>
                      {/* eslint-disable-next-line */}
                      <a className="ant-dropdown-link">
                        <Icon type={menuItem.icon} className="nav-icon" style={{marginRight: 0}} /> <Icon type="down" />
                      </a>
                    </Dropdown>
                  </Menu.Item>
                );
              }
      
              return (
                <Menu.Item key={menuItem.url} title={menuItem.title}>
                  <Link to={menuItem.url}>
                    <Icon type={menuItem.icon} className="nav-icon"/>
                  </Link>
                </Menu.Item>
              )
            })}
          </Menu>
        </AppHeader>
        <Layout.Content className="app-content">
          <div className="container">
            {children}
          </div>
        </Layout.Content>
      </Layout>
    );
  }
}

export default DashboardLayout;