import { Layout } from 'antd';
import React from 'react';
import { Link } from 'react-router-dom';

class JobHeader extends React.Component {
  render() {
    const { bannerBackground, content, additionalClass } = this.props;
    return (
      <>
        <header className='main-header'>
          <div className='header-upper'>
            <div className='auto-container'>
              <div className='inner-container'>
                <div className='pull-left logo-box'>
                  <div className='logo'>
                    <Link to='/'>
                      <img
                        src={require('../assets/logo.png')}
                        alt='Flow mitrais'
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </header>
        <Layout.Content
          className={'page-banner-section ' + additionalClass}
          style={{
            backgroundImage: 'url(' + bannerBackground + ')',
          }}
        >
          <div className='auto-container'>{content}</div>
        </Layout.Content>
      </>
    );
  }
}

export default JobHeader;
