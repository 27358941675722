import {request} from "./BaseAPIRequest";
import {apiUrls} from "../route/Routes";

export const getEmailTemplates = (options = {}) => {
  return request({
    url: apiUrls.emailTemplate,
    method: 'GET',
    ...options
  });
};

export const getEmailTemplateById = (id, options = {}) => {
  return request({
    url: `${apiUrls.emailTemplate}/${id}`,
    method: 'GET',
    ...options
  });
};
  
export const updateEmailTemplate = (payload, options = {}) => {
  return request({
    url: apiUrls.emailTemplate,
    method: 'PUT',
    body: JSON.stringify(payload),
    ...options
  });
};