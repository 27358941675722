import React from 'react';
import {Button, Divider, Form, notification} from 'antd';
import {applyJob} from './JobAPI';
import CompetencyForm from "../job/CompetencyForm";
import PersonalDetailForm from "../job/PersonalDetailForm";
import DocumentForm from "../job/DocumentForm";
import  Cookies from 'js-cookie';
class ApplyForm extends React.Component {
  dateTimeFormat = "YYYY-MM-DD";

  constructor(props) {
    super(props);

    this.state = {
      fileList: [],
      uploading: false,

      origin_referrer: Cookies.get('origin_referrer'),
      utmSource: Cookies.get('utmSource'),
      utmMedium: Cookies.get('utmMedium'),
      utmCampaign: Cookies.get('utmCampaign'),
      utmTerm : Cookies.get('utmTerm'),
      utmContent : Cookies.get('utmContent')
    };
  }

  handleSubmit = (e) => {
    this.setState({
      uploading: true
    });
    
    e.preventDefault();

    this.props.form.validateFieldsAndScroll((error, formInput) => {
      if (error) {
        this.setState({
          uploading: false
        });
    
        return;
      }
  
      if (formInput.competencies === undefined || formInput.competencies.length === 0) {
        this.setState({
          uploading: false
        });
  
        notification.error({
          id: "apply-notification-error",
          message: "MITRAIS Online Recruitment",
          description: "At least one competency is required."
        });
    
        return;
      }
      
      const { fileList } = this.state;
      
      if (fileList.length === 0) {
        this.setState({
          uploading: false
        });
  
        notification.error({
          id: "apply-notification-error",
          message: "MITRAIS Online Recruitment",
          description: "At least one file is required."
        });
  
        return;
      }

      formInput.dateOfBirth = formInput.dateOfBirth.format(this.dateTimeFormat);
      formInput.files = fileList;
      formInput.vacancyId = this.props.vacancyId;

      formInput.originReferrer = this.state.origin_referrer;
      formInput.utmSource = this.state.utmSource;
      formInput.utmMedium = this.state.utmMedium;
      formInput.utmCampaign = this.state.utmCampaign;
      formInput.utmTerm = this.state.utmTerm;
      formInput.utmContent = this.state.utmContent;

      applyJob(formInput).then(() => {
        notification.success({
          id: "apply-notification-success",
          message: "MITRAIS Online Recruitment",
          description: "Thank you! We've received your job application. We'll contact your email for further information.",
        });
  
        this.setState({
          uploading: false
        });

        // added information for Google Tag Manager (GTM)
        window.dataLayer = window.dataLayer || [];
        window.dataLayer.push({
          'event':'formSubmission',
          'formType':'Job Application'
        });

        this.props.history.push("/");
      }).catch(error => {
        let errorMessage = "Sorry! We cannot process your request at the moment. Please try again later.";

        if (typeof error.messages === 'object' && error.messages !== null) {
          let d = [];
          let counter = 1;

          for (let [fileName, errorMessage] of Object.entries(error.messages)) {
            d.push(<p key={`${fileName}-${counter}`}> - {fileName} : {errorMessage}</p>);
            counter++;
          }

          errorMessage = <>{d}</>;
        }

        notification.error({
          id: "apply-notification-error",
          message: "MITRAIS Online Recruitment",
          description: errorMessage
        });

        this.setState({
          uploading: false
        });
      });
    });
  };

  handleFileList= fileList => {
    this.setState(() => ({fileList}));
  };

  render() {
    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
      },
      wrapperCol: {
        xs: { span: 24 },
      },
    };
    const tailFormItemLayout = {
      wrapperCol: {
        xs: {
          span: 24,
          offset: 0,
        },
        sm: {
          span: 16,
          offset: 8,
        },
      },
    };

    const { uploading, fileList } = this.state;

    return(
        <Form id="apply-form" layout="vertical" onSubmit={this.handleSubmit}>
          <PersonalDetailForm form={this.props.form} formItemLayout={formItemLayout} />
          <CompetencyForm form={this.props.form} formItemLayout={formItemLayout} />
          <DocumentForm fileList={fileList} handleFileList={this.handleFileList} />
          <Divider />
          <Form.Item {...tailFormItemLayout}>
            <input type="hidden" id="origin_referrer" name="origin_referrer" value={this.state.origin_referrer}></input>
            <input type="hidden" id="utm_source" name="utm_source" value={this.state.utmSource}></input>
            <input type="hidden" id="utm_medium" name="utm_medium" value={this.state.utmMedium}></input>
            <input type="hidden" id="utm_campaign" name="utm_campaign" value={this.state.utmCampaign} />
            <input type="hidden" id="utm_term" name="utm_term" value={this.state.utmTerm} />
            <input type="hidden" id="utm_content" name="utm_content" value={this.state.utmContent} />
            <Button type="primary" htmlType="submit" loading={uploading}>Apply Now</Button>
          </Form.Item>
        </Form>
    );
  }
}

const JobApplyForm = Form.create({ name: 'apply' })(ApplyForm);

export default JobApplyForm;