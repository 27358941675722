import React, { Component } from 'react';
import { notification, } from 'antd';
import './ViewVacancy.css';
import { getVacancyById } from '../util/VacancyAPI';
import {NOTIFICATION_HEADER} from "../util/constants";
import LoadingIndicator from "../common/LoadingIndicator";

class ViewVacancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      vacancy: {},
      isLoading: true
    }
  }

  componentDidMount() {
    getVacancyById(this.props.match.params.id)
      .then(response => {
        this.setState({
          vacancy: response.object,
          isLoading: false
        });

      }).catch(error => {
        notification.error({
          message: NOTIFICATION_HEADER,
          description: error.message || 'Error! Cannot load the vacancy data. Please try again later.'
        });
      });
  }
  
  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator/>;
    }
    
    const { vacancy } = this.state;
    const { vacancyFunction } = vacancy;
    
    return (
      <div className="view-vacancy-container">
        <h1>{vacancy.position}</h1>
        <p>{vacancyFunction.name}</p>
        <div dangerouslySetInnerHTML={{ __html: vacancy.description }}/>
      </div>
    );
  }
}

export default ViewVacancy;
