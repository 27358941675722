import React from 'react';
import { Route } from 'react-router-dom';
import { Layout } from 'antd';
import AppFooter from '../common/AppFooter';

export default ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <Layout>
          <Component {...rest} {...props} />
          <AppFooter></AppFooter>
        </Layout>
      )}
    />
  );
};
