import React, {Component} from 'react';
import {Button, Divider, Icon, Input, Switch, Table, Tabs} from 'antd';
import Highlighter from 'react-highlight-words';
import './ListVacancy.css';
import {getVacancies, updateVacancyStatus} from '../util/VacancyAPI';
import {getVacancyFunctions} from '../util/VacancyFunctionAPI';
import {Link} from 'react-router-dom';
import LoadingIndicator from "../common/LoadingIndicator";
import {privateUrls} from "../route/Routes";

class ListVacancy extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      vacancies: [],
      vacancyFunctions: [],
      isLoading: true
    };
  }

  mController = new AbortController();
  mSignal = this.mController.signal;

  componentDidMount() {
    getVacancies({
        signal: this.mSignal
      })
      .then(response => {
        this.setState({
            vacancies: response,
            isLoading: false
          }, () => getVacancyFunctions({
            signal: this.mSignal
          })
          .then(response => {
            this.setState({
              vacancyFunctions: response,
              isLoading: false
            });
          }).catch(() => {
            this.setState({
              isLoading: false
            })
          }));
      }).catch(() => {
        this.setState({
          isLoading: false
        })
      });
  }

  componentWillUnmount() {
    this.mController.abort();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node; }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}/>
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}>
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}>
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}/>
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleSwitchChange = (event, id) => {
    let value = (event === false ? "inactive" : "publish");
    const formRequest = {
      id: id,
      status: value,
    };
    updateVacancyStatus(formRequest)
      .then(() => {
      }).catch(() => {
        this.setState({
          isLoading: false
        })
      });
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator />;
    }
    
    const { vacancies, vacancyFunctions } = this.state;

    const columns = [{
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      ...this.getColumnSearchProps('position'),
    }, {
      title: 'Job Function',
      dataIndex: 'function',
      key: 'function',
      ...this.getColumnSearchProps('function'),
    },  {
      title: 'Location',
      dataIndex: 'location',
      key: 'location',
      ...this.getColumnSearchProps('location'),
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (text, record) => (
        <span>
            <Switch 
              checkedChildren="Publish" 
              unCheckedChildren="Inactive" 
              defaultChecked={text === "publish"}
              onChange={(event) => this.handleSwitchChange(event, record.key)} />
        </span>
      ),
    }, {
      title: 'Last Published By',
      dataIndex: 'updatedBy',
      key: 'updatedBy'
    }, {
      title: 'Last Published At',
      dataIndex: 'updatedAt',
      key: 'updatedAt'
    }, {
      title: 'Action',
      key: 'action',
      className: 'action',
      render: (text, record) => (
        <span>
          <Link id="vacancy-view" to={`${privateUrls.vacancyList}/${record.key}`}>View</Link>
          <Divider type="vertical" />
          <Link id={"vacancy-update-" + record.key} to={`${privateUrls.vacancyUpdate}/${record.key}`}>Edit</Link>
        </span>
      ),
    }];

    const data = [];
    vacancies.forEach(vacancy => {
      data.push(
        {
          key: vacancy.id,
          position: vacancy.position,
          function: vacancy.vacancyFunction.name,
          location: vacancy.location,
          status: vacancy.status,
          updatedBy: vacancy.updatedBy,
          updatedAt: vacancy.updatedAt,
        }
      );
    });

    const columnsFunc = [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...this.getColumnSearchProps('name'),
    }, {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      ...this.getColumnSearchProps('description'),
    }, {
      title: 'Last Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy'
    }, {
      title: 'Last Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt'
    }, {
      title: 'Action',
      key: 'action',
      className: 'action',
      render: (text, record) => (
        <span>
          <Link id={"vacancy-update-" + record.key} to={`${privateUrls.vacancyFunctionUpdate}/${record.key}`}>Edit</Link>
        </span>
      ),
    }];

    const dataFunc = [];
    vacancyFunctions.forEach(vacancyFunction => {
      dataFunc.push(
        {
          key: vacancyFunction.id,
          name: vacancyFunction.name,
          description: vacancyFunction.description,
          updatedBy: vacancyFunction.updatedBy.name,
          updatedAt: vacancyFunction.updatedAt,
        }
      );
    });

    return (
      <div className="list-vacancy-container">
      <Tabs defaultActiveKey={this.props.location.hash}>
        <Tabs.TabPane tab="Vacancy List" key="#vacancy">
          <Link id="vacancy-create" to={privateUrls.vacancyCreate}><Icon type="plus-circle" /> Create Vacancy</Link>
          <Table
            columns={columns}
            dataSource={data}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'] }} />
        </Tabs.TabPane>
        <Tabs.TabPane tab="Job Function List" key="#jobFunction">
          <Link id="function-create" to={privateUrls.vacancyFunctionCreate}><Icon type="plus-circle" /> Create Job Function</Link>
          <Table
            columns={columnsFunc}
            dataSource={dataFunc}
            pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'] }} />
        </Tabs.TabPane>
      </Tabs>
      </div>
    );
  }
}

export default ListVacancy;