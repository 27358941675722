export const ACCESS_TOKEN = 'accessToken';
export const TYPE_TOKEN = 'Bearer';
export const NOTIFICATION_HEADER = "MORE";

export const NAME_MIN_LENGTH = 4;
export const NAME_MAX_LENGTH = 100;

export const USERNAME_MIN_LENGTH = 6;
export const USERNAME_MAX_LENGTH = 20;

export const PASSWORD_MIN_LENGTH = 6;
export const PASSWORD_MAX_LENGTH = 20;

export const GTM_ID = process.env.GTM_ID || 'GTM-58RS5QG';

export const TINY_MCE_API_KEY = process.env.REACT_APP_TINY_MCE_API_KEY;