import { LOGIN, LOGOUT } from "./actions";

const initialState = {
    isAuthenticated: false,
    currentUser: null
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                isAuthenticated: true,
                currentUser: action.currentUser
            };
        case LOGOUT:
            return {
                isAuthenticated: false,
                currentUser: null
            };
        default:
            return state;
    }
};

export default reducer;