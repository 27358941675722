import Jobs from "../publicView/Jobs";
import JobDetail from "../publicView/JobDetail";
import Dashboard from "../common/Dashboard";
import ListVacancy from "../vacancy/ListVacancy";
import CreateVacancy from "../vacancy/CreateVacancy";
import ViewVacancy from "../vacancy/ViewVacancy";
import UpdateVacancy from "../vacancy/UpdateVacancy";
import ListUser from "../user/ListUser";
import UpdateUser from "../user/UpdateUser";
import CreateUser from "../user/CreateUser";
import ListEmailTemplate from "../emailtemplate/ListEmailTemplate";
import UpdateEmailTemplate from "../emailtemplate/UpdateEmailTemplate";
import ListCandidate from "../candidate/ListCandidate";
import ViewCandidate from "../candidate/ViewCandidate";
import InquiryCreate from "../inquiry/InquiryCreate";
import CreateFunction from "../vacancyfunction/CreateFunction";
import UpdateFunction from "../vacancyfunction/UpdateFunction";

export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const apiUrls = {
  login: "/auth/login",
  checkValidToken: "/user/me",
  user: "/user",
  checkUsernameAvailability: "/user/checkUsernameAvailability",
  vacancy: "/vacancy",
  vacancyFunction: "/vacancyfunction",
  candidate: "/candidate",
  emailTemplate: "/templates",
  inquiry: "/applications",
  inquiryAddDocument: "/applications/addDocument",
  note: "/notes",
  document: "/document",
  job: "/jobs"
};

const basePrivateUrl = "/hrm";
let privateUrls = {
  basePath: "",
  dashboard: "/",
  login: "/login",
  vacancyList: "/vacancies",
  vacancyCreate: "/vacancies/create",
  vacancyUpdate: "/vacancies/update",
  inquiryList: "/inquiries",
  inquiryCreate: "/inquiries/create",
  userList: "/users",
  userCreate: "/users/create",
  userUpdate: "/users/update",
  roleList: "/roles",
  emailTemplateList: "/email-templates",
  emailTemplateUpdate: "/email-templates/update",
  vacancyFunctionCreate: "/vacancy-functions/create",
  vacancyFunctionUpdate: "/vacancy-functions/update",
};
Object.keys(privateUrls).map(key => (privateUrls[key] = basePrivateUrl + privateUrls[key]));

const basePublicUrl = "";
let publicUrls = {
  basePath: "/",
  jobList: "/jobs"
};
Object.keys(publicUrls).map(key => (publicUrls[key] = basePublicUrl + publicUrls[key]));

export {privateUrls, publicUrls};

export const privateRoutes = [
  {
    path: privateUrls.dashboard,
    component: Dashboard
  },
  {
    path: privateUrls.vacancyList,
    component: ListVacancy
  },
  {
    path: privateUrls.vacancyCreate,
    component: CreateVacancy
  },
  {
    path: `${privateUrls.vacancyList}/:id`,
    component: ViewVacancy
  },
  {
    path: `${privateUrls.vacancyUpdate}/:id`,
    component: UpdateVacancy
  },
  {
    path: privateUrls.userList,
    component: ListUser
  },
  {
    path: privateUrls.userCreate,
    component: CreateUser
  },
  {
    path: `${privateUrls.userUpdate}/:id`,
    component: UpdateUser
  },
  {
    path: privateUrls.emailTemplateList,
    component: ListEmailTemplate
  },
  {
    path: `${privateUrls.emailTemplateUpdate}/:id`,
    component: UpdateEmailTemplate
  },
  {
    path: privateUrls.inquiryList,
    component: ListCandidate
  },
  {
    path: privateUrls.inquiryCreate,
    component: InquiryCreate
  },
  {
    path: `${privateUrls.inquiryList}/:id`,
    component: ViewCandidate
  },
  {
    path: privateUrls.vacancyFunctionCreate,
    component: CreateFunction
  },
  {
    path: `${privateUrls.vacancyFunctionUpdate}/:id`,
    component: UpdateFunction
  },
];

export const publicRoutes = [
  {
    path: publicUrls.basePath,
    component: Jobs
  },
  {
    path: `${publicUrls.jobList}/:id`,
    component: JobDetail
  }
];