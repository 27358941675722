const benefits = [
  {
    icon: 'ic-job-security',
    title: 'Job Security',
    description:
      'Has been growing for 30+ years, Mitrais is getting stronger during the pandemic by making an exceptional profit. Our Software Engineers stay for six years on average and many grew their career all the way up to the top management.',
  },
  {
    icon: 'ic_profdev',
    title: 'Professional Development',
    description:
      'By joining free business communication classes and technical bootcamps from JavaScript to AWS/Azure certifications for free, our team are confident of becoming word-class Software Engineers!',
  },
  {
    icon: 'ic_wlb',
    title: 'Work-Life Balance',
    description:
      'Working from the heart of the tourism area or the comfy of your home, healthy working hours, social clubs, and fun events are only some privileges Mitrais’ staff enjoy. Experience the ultimate work-life balance!',
  },
  {
    icon: 'ic-mitrais-culture',
    title: 'Mitrais Culture',
    description:
      'Mitrais staff talk enthusiastically about the friendly and supportive Mitrais Culture. Employee well-being is the priority and is checked one by one during the pandemic.',
  },
  {
    icon: 'ic-social-events',
    title: 'Social Clubs',
    description:
      'Get a ticket to participate in the annual Mitrais Innovathon for a chance to win great prizes and see your brilliant business plan come to life. Meet like-minded friends at Mitrais’ sponsored clubs, from photography to snorkeling; you name it.',
  },
  {
    icon: 'ic-health-supports',
    title: 'Health Support',
    description:
      'For you and your loved ones, Mitrais offers BPJS, international-class inpatient and outpatient health insurance, subsidies for spectacles and soft lenses, dental care, free vaccinations, psychotherapy treatment, and regular medical checkups of the highest standard.',
  },
];

/**
 * Maximum character:
 * testimonials.content <= 368 including space
 * testimonials.name && testimonials.role <= 33 including space
 */
const testimonials = [
  {
    name: 'Matthew Kevin K',
    role: 'Analyst Programmer',
    content:
      "Since I joined Mitrais, I’ve rarely worked overtime. I have much time on my hand after work and on the weekends; therefore, I can always manage my time for my family and my hobbies.",
    image: 'kevin',
  },
  {
    name: 'Apriliana Nur R',
    role: 'Analyst Programmer',
    content:
      "I can really see that Mitrais backs up employees’ work-life balance that enables parents like us to do our duties with WFH flexibility. I personally always work from the office and meet colleagues face to face; it helps me focus. But for parents who have their own needs, being able to opt for WFH or WFO is an A+ thing.",
    image: 'ana',
  },
  {
    name: 'Vincencia Tika Dianti',
    role: 'Software Tester',
    content:
      'I’d like to recommend to everyone that they join Mitrais. The people are nice and friendly, and the company provides a good environment in which to grow our skill and experience.',
    image: 'tika',
  },
  {
    name: 'Nanda Nurcholis',
    role: 'Programmer',
    content:
      'If you are determined to learn more about IT and have stick-to-it-iveness, then I think Mitrais is one of the best places you will ever want to grow with.',
    image: 'nanda',
  },
  {
    name: 'Roby Eka Pramudita',
    role: 'Analyst Programmer',
    content:
      "Mitrais places a great emphasis on work-life balance. To make things even more appealing, Mitrais provides its employees with a wide range of benefits. I'm grateful to be a part of Mitrais in many ways",
    image: 'robby',
  },
  {
    name: 'Cut Meutia Riadharma',
    role: 'Analyst',
    content:
      "A journey of nearly a decade is definitely not a short one. I should admit that during my years of working here, I've fallen in love with Mitrais! The work-life balance culture that Mitrais offers is one of the reasons why I've stayed this long.",
    image: 'cut',
  },
];

const warningDescription =
  'Beware of online scams such as persons posing to represent the Mitrais Recruitment Team or other recruiting-related personnel by sending unsolicited text messages and emails asking you to provide personal information, transfer money, or do any other specific activities.';

module.exports = {
  benefits,
  testimonials,
  warningDescription,
};
