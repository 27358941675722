import React from 'react';
import { DatePicker, Form, Input, InputNumber } from 'antd';
import { PhoneInput } from './PhoneInput';
import moment from 'moment';

class PersonalDetailForm extends React.Component {
  render() {
    const {
      form: { getFieldDecorator },
      formItemLayout = {},
    } = this.props;
    const dateTimeFormat = 'YYYY-MM-DD';
    const validAge = moment().subtract(18, 'years');

    return (
      <div className='personal-detail-form mb-2'>
        <h4>
          <strong>Personal Detail</strong>
        </h4>
        <p>
          <small>
            <span className='text-danger'>*</span> All fields are required.
          </small>
        </p>
        <Form.Item {...formItemLayout} label='Name' hasFeedback>
          {getFieldDecorator('name', {
            rules: [
              {
                required: true,
                message: 'Candidate full name is required.',
                whitespace: true,
              },
            ],
          })(<Input placeholder='Please enter candidate full name' />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label='E-mail' hasFeedback>
          {getFieldDecorator('email', {
            rules: [
              {
                type: 'email',
                message: 'The input is not a valid E-mail.',
              },
              {
                required: true,
                message: 'Candidate E-mail is required.',
              },
            ],
          })(
            <Input placeholder='Please enter candidate primary email address' />
          )}
        </Form.Item>
        <Form.Item {...formItemLayout} label='Phone Number' hasFeedback>
          {getFieldDecorator('phone', {
            rules: [
              {
                required: true,
                message: 'Phone number is required.',
              },
            ],
            initialValue: '',
          })(
            <PhoneInput
              country='ID'
              className='ant-input'
              placeholder='Please enter candidate phone number'
              maxLength={17}
            />
          )}
        </Form.Item>
        <Form.Item
          {...formItemLayout}
          label='Date of Birth'
          help='Candidate age should be at least 18 years old to apply for this job.'
        >
          {getFieldDecorator('dateOfBirth', {
            rules: [
              {
                type: 'boolean',
                required: true,
                whitespace: true,
                transform: (value) => {
                  return value <= validAge || undefined;
                },
                message:
                  'Candidate age should be at least 18 years old to apply for this job.',
              },
            ],
          })(
            <DatePicker
              format={dateTimeFormat}
              disabledDate={(current) => {
                return current > validAge;
              }}
              showToday={false}
              defaultPickerValue={validAge}
              style={{ width: '100%' }}
              placeholder='Select a date of birth'
            />
          )}
        </Form.Item>
        <Form.Item {...formItemLayout} label='Nationality' hasFeedback>
          {getFieldDecorator('nationality', {
            rules: [
              { required: true, message: 'Please input your nationality!' },
            ],
          })(<Input placeholder='Please enter your nationality' />)}
        </Form.Item>
        <Form.Item {...formItemLayout} label='Current Salary' hasFeedback>
          {getFieldDecorator('currentSalary', {
            rules: [
              {
                required: true,
                message: 'Candidate current salary is required.',
                whitespace: true,
                type: 'number',
              },
            ],
          })(
            <InputNumber
              formatter={(value) =>
                `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/Rp\s?|(,*)/g, '')}
              style={{ width: '100%' }}
            />
          )}
        </Form.Item>
        <Form.Item {...formItemLayout} label='Expected Salary' hasFeedback>
          {getFieldDecorator('expectedSalary', {
            rules: [
              {
                required: true,
                message: 'Candidate expected salary is required.',
                whitespace: true,
                type: 'number',
              },
            ],
          })(
            <InputNumber
              formatter={(value) =>
                `Rp ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/Rp\s?|(,*)/g, '')}
              style={{ width: '100%' }}
            />
          )}
        </Form.Item>
      </div>
    );
  }
}

export default PersonalDetailForm;
