import React from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, Divider, Layout, Row } from 'antd';
import { getJobById } from './JobAPI';
import JobApplyForm from './JobApplyForm';
import LoadingIndicator from '../common/LoadingIndicator';
import JobHeader from './JobHeader';
import './Job.css';
import { Helmet } from 'react-helmet';

class JobDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      job: {},
      isLoadingData: true,
      bannerContent: (str) => (
        <>
          <h1>{str}</h1>
          <Divider className='job-detail-divider' />
          <div className='gptw mt-4'>
            <div className='description'>
              <p>Join us and be part of</p>
              <h4>Mitrais</h4>
            </div>
          </div>
        </>
      ),
    };
  }

  mController = new AbortController();
  mSignal = this.mController.signal;

  componentDidMount = async () => {
    await getJobById(this.props.match.params.id, { signal: this.mSignal })
      .then((response) => {
        this.setState({
          job: response.object,
          isLoadingData: false,
        });
      })
      .catch(() => {
        this.setState({
          isLoadingData: false,
        });
        this.props.history.push('/notfound');
      });
  };

  componentWillUnmount() {
    this.mController.abort();
  }

  render() {
    if (this.state.isLoadingData) {
      return <LoadingIndicator />;
    }

    const { job, isLoadingData, bannerContent } = this.state;
    console.log(
      '🚀 ~ file: JobDetail.js ~ line 54 ~ JobDetail ~ render ~ job',
      job
    );

    return (
      <>
        <Helmet>
          {job.seoTitle ? <title>{job.seoTitle}</title> : null}
          {job.seoDescription ? <meta name='description' content={job.seoDescription} /> : null}
        </Helmet>
        <JobHeader
          content={bannerContent(job.position)}
          bannerBackground={require('../assets/2.jpg')}
          additionalClass={'h-50 style-two'}></JobHeader>
        <Layout.Content className='project-detail-section'>
          <div className='auto-container'>
            <div className='upper-box mb-2'>
              <Link to='/' className='btn-style-one text-white back-projects'>
                {'<- Back to Home'}
              </Link>

              <Card loading={isLoadingData} id='job-detail' bordered={false}>
                <h3 className='mb-2 fw-bold ls-1'>
                  Build your Career with Mitrais!
                </h3>
                <div dangerouslySetInnerHTML={{ __html: job.description }} />
              </Card>
            </div>
            <h4 className='fw-bold ls-1'>Application Form</h4>
            <Divider />
            <Row gutter={48}>
              <Col sm={{ span: 16 }}>
                <JobApplyForm history={this.props.history} vacancyId={job.id} />
              </Col>
              <Col sm={{ span: 8 }} style={{ paddingBottom: '16px' }}>
                <div>
                  <img
                    src={require('../assets/more_banner_mitrais_gptw.gif')}
                    alt=''
                    className='img-fluid'
                  />
                </div>
              </Col>
            </Row>
          </div>
        </Layout.Content>
      </>
    );
  }
}

export default JobDetail;
