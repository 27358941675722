import React, {Component} from 'react';
import {Button, Col, Form, Input, notification, Row, Select} from 'antd';
import './UpdateVacancy.css';
import {NOTIFICATION_HEADER} from '../util/constants';
import {getVacancyById, updateVacancy} from '../util/VacancyAPI';
import {getVacancyFunctions} from '../util/VacancyFunctionAPI';
import {Link} from 'react-router-dom';
import LoadingIndicator from "../common/LoadingIndicator";
import {TextEditor} from "../editor/TextEditor";
import {privateUrls} from "../route/Routes";

class UpdateVacancy extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      vacancy: null,
      vacancyFunctions: [],
      isLoading: true
    };
  }

  mController = new AbortController();
  mSignal = this.mController.signal;

  handleSubmit = (event) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((error, formInput) => {
      if (error) {
        return;
      }

      formInput.id = this.props.match.params.id;

      updateVacancy(formInput).then(() => {
        notification.success({
          message: NOTIFICATION_HEADER,
          description: "Success! The vacancy has been successfully updated.",
        });
        
        this.props.history.push(privateUrls.vacancyList);
      }).catch(error => {
        notification.error({
          message: NOTIFICATION_HEADER,
          description: error.message || 'Error! Something went wrong. Please try again later.'
        });
      });
    });
  };

  componentDidMount() {
    getVacancyById(this.props.match.params.id, {
        signal: this.mSignal
      }).then(response => {
        this.setState({
          vacancy: response.object,
          isLoading: false
        }, () => getVacancyFunctions()
          .then(response => {
            this.setState({
              vacancyFunctions: response,
              isLoading: false
            })
          }).catch(() => {
            this.setState({
              isLoading: false
            })
          })
        );
      }).catch(error => {
        this.setState({
          isLoading: false
        });
        notification.error({
          message: NOTIFICATION_HEADER,
          description: error.message || 'Error! Something went wrong. Please try again later.'
        });
      });
  }

  componentWillUnmount() {
    this.mController.abort();
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator />;
    }

    const { getFieldDecorator } = this.props.form;
    const { vacancy, vacancyFunctions } = this.state;

    return (
      <div className="update-vacancy-container">
        <h1>Update Vacancy</h1>
        <Form onSubmit={this.handleSubmit} className="vacancy-form">
          <Form.Item label="Position" hasFeedback>
            {getFieldDecorator('position', {
              rules: [{
                required: true, message: 'Vacancy position is required.',
              }],
              initialValue: vacancy.position
            })(
              <Input size="large" placeholder="Please input vacancy position" />
            )}
          </Form.Item>
          <Form.Item label="Job Function" hasFeedback>
            {getFieldDecorator('vacancyFunction', {
              rules: [{
                required: true, message: 'Job function is required.',
              }],
              initialValue: vacancy.vacancyFunction.id
            })(
              <Select size="large">
                <Select.Option value=""> - Choose - </Select.Option>
                {
                  vacancyFunctions &&
                  vacancyFunctions.map((val, index) => <Select.Option key={index} value={val.id}>{val.name}</Select.Option>)
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Location" hasFeedback>
            {getFieldDecorator('location', {
              rules: [{
                required: true, message: 'Vacancy position is required.',
              }],
              initialValue: vacancy.location
            })(
              <Input size="large" placeholder="Please input vacancy location" />
            )}
          </Form.Item>
          <Form.Item label="Status" hasFeedback>
            {getFieldDecorator('status', {
              rules: [{
                required: true, message: 'Vacancy position is required.',
              }],
              initialValue: vacancy.status
            })(
              <Select size="large">
                  <Select.Option value=""> - Choose - </Select.Option>
                  <Select.Option value="inactive">Inactive</Select.Option>
                  <Select.Option value="publish">Publish</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Description" hasFeedback>
            {getFieldDecorator('description', {
              rules: [{
                required: true, message: 'Vacancy description is required.',
              }],
              initialValue: vacancy.description,
              trigger: 'onEditorChange',
              getValueFromEvent: e => e && e.target ? e.target.getContent() : e
            })(
              <TextEditor />
            )}
          </Form.Item>
          <Form.Item label="SEO Title" hasFeedback>
            {getFieldDecorator('seoTitle', {
              rules: [{
                required: true, message: 'SEO Title is required.',
              }],
              initialValue: vacancy.seoTitle
            })(
              <Input size="large" placeholder="Please input vacancy SEO title" />
            )}
          </Form.Item>
          <Form.Item label="SEO Description" hasFeedback>
            {getFieldDecorator('seoDescription', {
              rules: [{
                required: true, message: 'SEO Description is required.',
              }],
              initialValue: vacancy.seoDescription
            })(
              <Input size="large" placeholder="Please input vacancy SEO description" />
            )}
          </Form.Item>
          <Form.Item>
            <Row>
              <Col span={8}>
              <Button type="primary" 
                  htmlType="submit" 
                  size="large" 
                  className="create-vacancy-form-button">Save</Button>
              </Col>
              <Col span={8} offset={8}>
                <Link  to={privateUrls.vacancyList}><Button className="create-vacancy-form-button" size="large">Cancel</Button></Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(UpdateVacancy);
