import {Editor} from '@tinymce/tinymce-react';
import React from "react";
import { TINY_MCE_API_KEY } from '../util/constants';

export const TextEditor = React.forwardRef((props, ref) => {
  const { addToInit = {}, addToToolbar = []} = props;
  return (
    <Editor
      apiKey={TINY_MCE_API_KEY}
      ref={ref}
      init={{
        plugins: ['table emoticons image lists textcolor'],
        toolbar: [
          'bold italic underline | strikethrough subscript superscript | fontselect fontsizeselect',
          'alignleft aligncenter alignright alignjustify | bullist numlist outdent indent | forecolor backcolor image emoticons | table',
          ...addToToolbar
        ],
        menubar: false,
        branding: false,
        ...addToInit
      }}
      {...props}
    />
  );
});