import React from 'react';
import styled from 'styled-components';
import {Draggable} from 'react-beautiful-dnd';
import {Avatar, List} from 'antd';
import {Link, withRouter} from 'react-router-dom';
import './candidate.css';
import {privateUrls} from "../route/Routes";

const Container = styled.div`
  border: 1px solid lightgrey;
  border-radius: 2px;
  padding: 8px;
  margin-bottom: 8px;
  background-color: ${props => props.isDragDisabled ? 'lightgrey' : props.isDragging ? 'lightgreen' : 'white'};
  display: flex;
`;

class Candidate extends React.Component {
  render() {
    return (
        <Draggable 
          draggableId={this.props.candidate.id} 
          index={this.props.index}
          >
          {(provided, snapshot) => (
            <Container
              {...provided.draggableProps}
              ref={provided.innerRef}
              isDragging={snapshot.isDragging}
            >
              <List.Item.Meta
                  avatar={<Avatar icon="user" {...provided.dragHandleProps}/>}
                  title={<Link to={`${privateUrls.inquiryList}/${this.props.candidate.id}`}>{this.props.candidate.name}</Link>}
                  description={this.props.candidate.position}
                />
            </Container>
          )}
        </Draggable>
    );
  }
}
export default withRouter(Candidate);