import React from 'react';
import {login} from '../../util/AuthAPI';
import './Login.css';
import {Redirect, withRouter} from 'react-router-dom';
import {Button, Form, Icon, Input, Layout, notification} from 'antd';
import {doLogin} from "../../store/actions";
import {privateUrls} from "../../route/Routes";
import {connect} from "react-redux";
import AppHeader from "../../common/AppHeader";

class LoginForm extends React.Component {
    handleSubmit = event => {
        event.preventDefault();
        
        this.props.form.validateFields((err, values) => !err  && this.props.handleLogin(Object.assign({}, values)));
    };
    
    render() {
        const { getFieldDecorator } = this.props.form;
        
        return(
          <Form onSubmit={this.handleSubmit} className="login-form">
              <Form.Item>
                  {getFieldDecorator('username', {
                      rules: [{ required: true, message: 'Please input your username or email!' }],
                  })(
                    <Input
                      prefix={<Icon type="user" />}
                      size="large"
                      placeholder="Username" />
                  )}
              </Form.Item>
              <Form.Item>
                  {getFieldDecorator('password', {
                      rules: [{ required: true, message: 'Please input your Password!' }],
                  })(
                    <Input
                      prefix={<Icon type="lock" />}
                      size="large"
                      type="password"
                      placeholder="Password"  />
                  )}
              </Form.Item>
              <Form.Item>
                  <Button type="primary" htmlType="submit" size="large" className="login-form-button">Login</Button>
              </Form.Item>
          </Form>
        );
    }
}

LoginForm = Form.create()(LoginForm);

class Login extends React.Component {
    constructor(props) {
        super(props);
        
        this.state = {
            redirectToReferrer: props.isAuthenticated
        };
    }
    
    handleLogin = payload => {
        login(payload)
          .then(response => {
              this.props.doLogin(response);
              this.setState({
                  redirectToReferrer: true
              })
          }).catch(error => {
            if (error.status === 401) {
                notification.error({
                    message: 'MORE',
                    description: 'Your Username or Password is incorrect. Please try again!'
                });
            } else {
                notification.error({
                    message: 'MORE',
                    description: error.message || 'Sorry! Something went wrong. Please try again later.'
                });
            }
        });
    };
    
    render() {
        const { redirectToReferrer } = this.state;
        const { from } = this.props.location.state || { from: { pathname: privateUrls.basePath } };
        
        if (redirectToReferrer) {
            return <Redirect push to={from} />;
        }
        
        return (
          <Layout className="app-container">
              <AppHeader homeUrl={privateUrls.basePath} />
              <Layout.Content className="app-content">
                  <div className="container">
                    <div className="login-container">
                        <h1 className="page-title">Login</h1>
                        <div className="login-content">
                            <LoginForm handleLogin={this.handleLogin}/>
                        </div>
                    </div>
                  </div>
              </Layout.Content>
          </Layout>
        );
    }
}

const mapStateToProps = state => ({isAuthenticated: state.isAuthenticated});
const mapDispatchToProps = dispatch => ({doLogin: payload => dispatch(doLogin(payload))});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Login));