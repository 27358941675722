import React from 'react';
import { Button, Icon, notification, Upload, Row, Col } from 'antd';
import { NOTIFICATION_HEADER } from '../util/constants';

class DocumentForm extends React.Component {
  render() {
    const { fileList, handleFileList } = this.props;
    const uploadProps = {
      onRemove: (file) => {
        const index = fileList.indexOf(file);
        const newFileList = fileList.slice();
        newFileList.splice(index, 1);

        return handleFileList(newFileList);
      },
      beforeUpload: (file) => {
        const validFileTypes = [
          'image/png',
          'image/jpeg',
          'image/bmp',
          'application/pdf',
          'application/msword',
          'application/rtf',
        ];
        const isValidFormat = validFileTypes.indexOf(file.type) > -1;

        if (!isValidFormat) {
          notification.error({
            message: NOTIFICATION_HEADER,
            description:
              'Allowed file formats are: .doc, .pdf, .rtf, .png, .jpg, and .bmp.',
          });
        }

        const isValidSize = file.size / 1024 / 1024 < 5;

        if (!isValidSize) {
          notification.error({
            message: NOTIFICATION_HEADER,
            description: 'Maximum file size is 5MB.',
          });
        }

        if (isValidFormat && isValidSize) {
          handleFileList([...fileList, file]);
        }

        return false;
      },
      fileList,
      accept: '.doc,.pdf,.rtf,.png,.jpg,.bmp',
    };

    return (
      <>
        <Row>
          <Col sm={{ span: 16 }}>
            <h5>
              <strong>Documents</strong>
            </h5>
          </Col>
          <Col sm={{ span: 8 }} type='flex' align='right'>
            <Upload {...uploadProps}>
              <Button id='apply_upload' type='primary' ghost>
                <Icon type='upload' /> Attach File(s)
              </Button>
            </Upload>
          </Col>
        </Row>
        Please upload your supporting documents <strong>in English</strong> to
        let us know you better.
        <ul>
          <li>Extensions : .doc, .pdf, .rtf, .png, .jpg, and .bmp.</li>
          <li>Maximum size (@): 5MB</li>
        </ul>
      </>
    );
  }
}

export default DocumentForm;
