import React, {Component} from 'react';
import {Button, DatePicker, Form, Icon, Input, Modal, notification, Select, Table, Tabs} from 'antd';
import './ListCandidate.css';
import {Link, withRouter} from 'react-router-dom';
import XLSX from 'xlsx';
import styled from 'styled-components';
import {DragDropContext} from 'react-beautiful-dnd';
import Column from '../dnd/column';
import {getInquiries, updateInquiryStatus} from '../util/InquiryAPI';
import {dndConvert} from './CandidateFunct';
import moment from 'moment';
import LoadingIndicator from "../common/LoadingIndicator";
import {privateUrls} from "../route/Routes";
import {NOTIFICATION_HEADER} from "../util/constants";

const Container = styled.div`
  display: flex;
`;
const { RangePicker } = DatePicker;

class ListCandidate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      candidates: [],
      columns: {
        'NEW': {
          id: 'NEW',
          title: 'NEW',
          candidateIds: [],
        },
        'TALENT POOL': {
          id: 'TALENT POOL',
          title: 'TALENT POOL',
          candidateIds: [],
        },
        'HIRED': {
          id: 'HIRED',
          title: 'HIRED',
          candidateIds: [],
        },
        'DECLINED': {
          id: 'DECLINED',
          title: 'DECLINED',
          candidateIds: [],
        },
        'REJECTED': {
          id: 'REJECTED',
          title: 'REJECTED',
          candidateIds: [],
        },
        'UNQUALIFIED': {
          id: 'UNQUALIFIED',
          title: 'UNQUALIFIED',
          candidateIds: [],
        },
        'PROCESSED': {
          id: 'PROCESSED',
          title: 'PROCESSED',
          candidateIds: [],
        },
      },
      columnOrder: ['NEW', 'UNQUALIFIED', 'PROCESSED', 'HIRED', 'TALENT POOL', 'REJECTED', 'DECLINED']
    };
  }

  componentDidMount() {
    this.getAllColumnData();
    this.props.form.validateFields();
  }

  exportFileByFilter = () => {
    const excelData = [
      [ "Name", "Email", "Phone", "Current Salary", "Date Of Birth", "Nationality", "Expected Salary", "Position", "Apply Date", "Status"],
    ];
    const c = this.state.candidates;
    
    for (const key in c) {
      excelData.push(
        [
          c[key].name,
          c[key].email,
          c[key].phone,
          c[key].currentSalary,
          c[key].dateOfBirth,
          c[key].nationality,
          c[key].expectedSalary,
          c[key].position,
          c[key].applyDate,
          c[key].status,
        ]
      );
    }

    /* convert state to workbook */
    const ws = XLSX.utils.aoa_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "SheetJS");
    /* generate XLSX file and send to client */
    XLSX.writeFile(wb, "candidates-" + new Date().toJSON().slice(0,10).replace(/-/g,'/') + ".xlsx");
  };

  handleFilter = e => {
    e.preventDefault();
    
    this.props.form.validateFieldsAndScroll((error, formInput) => {
      if (error) {
        return;
      }
      
      if (Array.isArray(formInput.applyDate) && formInput.applyDate.length === 2) {
        formInput.start = formInput.applyDate[0].format('YYYY-MM-DD');
        formInput.end = formInput.applyDate[1].format('YYYY-MM-DD');
      } else {
        formInput.start = "";
        formInput.end = "";
      }
  
      getInquiries(formInput)
        .then(response => {
          const data = dndConvert(response);
          this.setState({
            columns: data.columns,
            candidates: data.candidates,
          });
        }).catch(() => {
          this.setState({
            isLoading: false
          })
        });
    });
  };

  getAllColumnData = () => {
    getInquiries()
      .then(response => {
        const data = dndConvert(response);
        this.setState({
          columns: data.columns,
          candidates: data.candidates, 
        });
      }).catch(() => {
        this.setState({
          isLoading: false
        })
      });
  };

  onDragEnd = result => {
    const { destination } = result;
    
    Modal.confirm({
      title: 'Move Job Application',
      content: <>
        <Form.Item label="Reason" help="Please put your reason to move this job application.">
          <Input.TextArea rows={5} id="update-status-input"/>
        </Form.Item>
      </>,
      okText: 'Save',
      className: 'modal-responsive',
      onOk: () => {
        const payload = {
          id: result.draggableId,
          status: destination.droppableId.toUpperCase(),
          reason: document.getElementById("update-status-input").value,
        };
  
        updateInquiryStatus(payload)
          .then(() => {
            notification.success({
              message: NOTIFICATION_HEADER,
              description: "Success! The job application has been moved to other status.",
            });
            this.handleDnD(result);
          }).catch(error => {
            notification.error({
              message: NOTIFICATION_HEADER,
              description: error.message || 'Error! Cannot perform status update at the moment. Please try again later.'
            });
        });
      }
    })
  };

  handleDnD = result => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (destination.droppableId === source.droppableId && destination.index === source.index) {
      return;
    }

    const start = this.state.columns[source.droppableId];
    const finish = this.state.columns[destination.droppableId];
  
    const startCandidateIds = Array.from(start.candidateIds);
    startCandidateIds.splice(source.index, 1);
    
    let newColumn = {};
    
    if (start === finish) {
      startCandidateIds.splice(destination.index, 0, draggableId);
    } else {
      const finishCandidateIds = Array.from(finish.candidateIds);
      finishCandidateIds.splice(destination.index, 0, draggableId);
      
      const newFinish = {
        ...finish,
        candidateIds: finishCandidateIds,
      };
      
      newColumn[newFinish.id] = newFinish;
    }
  
    const newStart = {
      ...start,
      candidateIds: startCandidateIds,
    };
    
    newColumn[newStart.id] = newStart;
    
    this.setState(state => ({
      ...state,
      columns: {
        ...state.columns,
        ...newColumn
      },
    }));
  };

  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator/>;
    }
    
    const { form: { getFieldDecorator } } = this.props;
    const { candidates, columns, columnOrder} = this.state;
    
    const tableColumns = [{
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.position.localeCompare(b.position),
    }, {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.name.localeCompare(b.name),
    }, {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.email.localeCompare(b.email),
    }, {
      title: 'Phone',
      dataIndex: 'phone',
      key: 'phone',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.phone.localeCompare(b.phone),
    }, {
      title: 'Nationality',
      dataIndex: 'nationality',
      key: 'nationality',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.nationality.localeCompare(b.phone),
    }, {
      title: 'Apply Date',
      dataIndex: 'applyDate',
      key: 'applyDate',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => moment(a.applyDate || 0).unix() - moment(b.applyDate || 0).unix(),
    }, {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      defaultSortOrder: 'ascend',
      sorter: (a, b) => a.status.localeCompare(b.status),
    }, {
      title: 'Action',
      fixed: 'right',
      width: 100,
      render: (text, record) => <Link to={`${privateUrls.inquiryList}/${record.id}`}>View</Link>,
    }];
    const data = [];
    const c = candidates;
    
    for (const key in c) {
      data.push(
        {
          key: key,
          id: c[key].id,
          position: c[key].position,
          name: c[key].name,
          email: c[key].email,
          phone: c[key].phone,
          nationality: c[key].nationality,
          applyDate: c[key].applyDate,
          status: c[key].status,
        }
      );
    }
    
    return (
      <div className="list-candidate-container">
        <h1>Application Job List</h1>
        <Form layout="inline" onSubmit={this.handleFilter} className="form-candidate-filter">
        <Form.Item>
          {getFieldDecorator('name', {initialValue: ""})(
            <Input placeholder="Candidate Name"/>
          )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('nationality', {initialValue: ""})(
              <Input placeholder="Nationality" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('position', {initialValue: ""})(
              <Input placeholder="Position" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('status', {initialValue: ""})(
              <Select>
                <Select.Option value=""> - Choose - </Select.Option>
                <Select.Option value="NEW">NEW</Select.Option>
                <Select.Option value="UNQUALIFIED">UNQUALIFIED</Select.Option>
                <Select.Option value="PROCESSED">PROCESSED</Select.Option>
                <Select.Option value="HIRED">HIRED</Select.Option>
                <Select.Option value="TALENT POOL">TALENT POOL</Select.Option>
                <Select.Option value="REJECTED">REJECTED</Select.Option>
                <Select.Option value="DECLINED">DECLINED</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('location', {initialValue: ""})(
              <Input placeholder="Job Location" />
            )}
          </Form.Item>
          <Form.Item>
            {getFieldDecorator('applyDate', {initialValue: []})(
              <RangePicker
                format="YYYY-MM-DD"
                placeholder={['Start Apply Date', 'End Apply Date']}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              <Icon type="search" /> Search
            </Button>
          </Form.Item>
        </Form>
        <Button
          className="list-candidate-download-filter"
          onClick={this.exportFileByFilter}
          size="default">
          <Icon type="download" /> Download Excel
        </Button>
        <Link to={privateUrls.inquiryCreate}>
          <Button className="list-candidate-download-filter" type="primary" ghost>
            <Icon type="form" /> Submit Job Application
          </Button>
        </Link>
        <Tabs defaultActiveKey="drag-drop">
          <Tabs.TabPane tab="Drag and Drop" key="drag-drop">
            <div className="wrapper-dnd">
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Container>
                  {columnOrder.map(columnId => {
                    const column = columns[columnId];
                    const mappedCandidates = column.candidateIds.map(candidateId => candidates[candidateId]);
                    
                    return <Column key={column.id} column={column} candidates={mappedCandidates}/>;
                  })}
                </Container>
              </DragDropContext>
            </div>
          </Tabs.TabPane>
          <Tabs.TabPane tab="Table" key="tabular">
            <Table
              columns={tableColumns}
              dataSource={data}
              scroll={{ x: 1300 }}
              pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'] }}
              />
          </Tabs.TabPane>
        </Tabs>
      </div>
    );
  }
}

export default Form.create({ name: 'filter' })(withRouter(ListCandidate));