import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './app/App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import { applyMiddleware, createStore } from 'redux';
import { Provider } from 'react-redux';
import thunk from 'redux-thunk';
import reducer from './store/reducer';
import TagManager from 'react-gtm-module';
import { GTM_ID } from './util/constants';

const tagManagerArgs = {
    gtmId: GTM_ID
}

TagManager.initialize(tagManagerArgs);



const store = createStore(reducer, applyMiddleware(thunk));

ReactDOM.render(<Provider store={store}><BrowserRouter><App/></BrowserRouter></Provider>, document.getElementById('root'));

serviceWorker.unregister();