import React, {Component} from 'react';
import {Button, Divider, Icon, Input, notification, Popconfirm, Table, Tag} from 'antd';
import Highlighter from 'react-highlight-words';
import './ListUser.css';
import {deleteUser, getUsers} from '../util/UserAPI';
import {Link, withRouter} from 'react-router-dom';
import {NOTIFICATION_HEADER} from '../util/constants';
import {privateUrls} from "../route/Routes";

class ListUser extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchText: '',
      user: [],
      isLoading: true
    };
  }

  mController = new AbortController();
  mSignal = this.mController.signal;

  componentDidMount = async () => {
    getUsers({signal : this.mSignal})
      .then(response => {
        this.setState({
          user: response,
          isLoading: false
        });
      }).catch(() => {
        this.setState({
          isLoading: false
        });
    });
  };

  componentWillUnmount() {
    this.mController.abort();
  }

  getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys, selectedKeys, confirm, clearFilters,
    }) => (
      <div style={{ padding: 8 }}>
        <Input
          ref={node => { this.searchInput = node; }}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => this.handleSearch(selectedKeys, confirm)}
          style={{ width: 188, marginBottom: 8, display: 'block' }}
        />
        <Button
          type="primary"
          onClick={() => this.handleSearch(selectedKeys, confirm)}
          icon="search"
          size="small"
          style={{ width: 90, marginRight: 8 }}
        >
          Search
        </Button>
        <Button
          onClick={() => this.handleReset(clearFilters)}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
      </div>
    ),
    filterIcon: filtered => <Icon type="search" style={{ color: filtered ? '#1890ff' : undefined }} />,
    onFilter: (value, record) => record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownVisibleChange: (visible) => {
      if (visible) {
        setTimeout(() => this.searchInput.select());
      }
    },
    render: (text) => (
      <Highlighter
        highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
        searchWords={[this.state.searchText]}
        autoEscape
        textToHighlight={text.toString()}
      />
    ),
  });

  handleSearch = (selectedKeys, confirm) => {
    confirm();
    this.setState({ searchText: selectedKeys[0] });
  };

  handleReset = (clearFilters) => {
    clearFilters();
    this.setState({ searchText: '' });
  };

  handleDelete = async (userId) => {
    await deleteUser(userId)
      .then(() => {
        notification.success({
          message: NOTIFICATION_HEADER,
          description: "Success! The user data has been deleted.",
        });
        this.props.history.push(privateUrls.userList);
      }).catch(error => {
        this.setState({
          isLoading: false
        });
        notification.error({
          message: NOTIFICATION_HEADER,
          description: error.message || 'Sorry! Something went wrong. Please try again!'
        });
      });
  };

  render() {
    const columns = [{
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      ...this.getColumnSearchProps('name'),
    }, {
      title: 'Username',
      dataIndex: 'username',
      key: 'username',
      ...this.getColumnSearchProps('username'),
    }, {
      title: 'Active',
      dataIndex: 'active',
      key: 'active',
      render: (text, record) => (<span>{record.active ? <Tag color="green">Active</Tag> : <Tag color="red">Inactive</Tag>}</span>),
    }, {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
    }, {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    }, {
      title: 'Action',
      key: 'action',
      render: (text, record) => (
        <span>
          <Link to={`${privateUrls.userUpdate}/${record.key}`}>Edit</Link>
          <Divider type="vertical" />
          <Popconfirm placement="rightBottom" title="Are you sure to delete this user?" onConfirm={() => this.handleDelete(record.key)} okText="Yes" cancelText="No">
            <Button type="link" style={{color: "red"}}>Delete</Button>
          </Popconfirm>
        </span>
      ),
    }];

    const data = [];
    this.state.user.forEach(user => {
      data.push(
        {
          key: user.id,
          name: user.name,
          username: user.username,
          active: user.active,
          createdAt: user.createdAt,
          updatedAt: user.updatedAt,
        }
      );
    });
    
    return (
      <div className="list-user-container">
        <h1>User List</h1>
        <Link to={privateUrls.userCreate}><Icon type="plus-circle" /> Create User</Link>
        <Table
          columns={columns}
          dataSource={data}
          loading={this.state.isLoading}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'] }} />
      </div>
    );
  }
}

export default withRouter(ListUser);