import React, {Component} from 'react';
import {Link, withRouter} from 'react-router-dom';
import './AppHeader.css';
import {Layout} from 'antd';

class AppHeader extends Component {
  render() {
    const { children, homeUrl = "/"} = this.props;
    
    return (
      <Layout.Header className="app-header">
        <div className="container">
          <div className="app-header-wrapper">
            <div className="app-title" >
              <Link to={homeUrl}>MITRAIS Online Recruitment</Link>
            </div>
            {children}
          </div>
        </div>
      </Layout.Header>
    );
  }
}

export default withRouter(AppHeader);