import React from 'react';
import { Table } from 'antd';
import './ListEmailTemplate.css';
import { getEmailTemplates } from '../util/EmailTemplateAPI';
import { Link, withRouter } from 'react-router-dom';
import LoadingIndicator from "../common/LoadingIndicator";
import {privateUrls} from "../route/Routes";

class ListEmailTemplate extends React.Component {
  constructor( props ) {
    super(props);
    this.state = {
      templates: [],
      isLoading: true
    };

    this.myController = new AbortController();
    this.mySignal = this.myController.signal;
  }

  componentDidMount() {
    getEmailTemplates({ signal: this.mySignal })
      .then(response => {
        this.setState({
          templates: response || [],
          isLoading: false
        });
      }).catch(() => {
        this.setState({
          isLoading: false
        })
    });
  }

  componentWillUnmount() {
    this.myController.abort();
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator/>;
    }
    
    const { templates } = this.state;
    
    const columns = [{
      title: 'Status',
      dataIndex: 'statusType',
      key: 'statusType'
    }, {
      title: 'Recipient',
      dataIndex: 'recipient',
      key: 'recipient'
    }, {
      title: 'Sender',
      dataIndex: 'sender',
      key: 'sender'
    }, {
      title: 'Subject',
      dataIndex: 'subject',
      key: 'subject'
    }, {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
    }, {
      title: 'Updated By',
      dataIndex: 'updatedBy',
      key: 'updatedBy',
    }, {
      title: 'Action',
      key: 'action',
      render: ( text, record ) => <Link to={`${privateUrls.emailTemplateUpdate}/${record.key}`}>Edit</Link>,
    }];

    const data = [];
    templates.forEach(template => {
      data.push(
        {
          key: template.id,
          recipient: template.recipient,
          sender: template.sender,
          subject: template.subject,
          updatedAt: template.updatedAt,
          updatedBy: template.updatedBy.name,
          statusType: template.statusType,
        }
      );
    });

    return (
      <div className="list-template-container">
        <h1>Email Template List</h1>
        <Table
          loading={this.state.isLoading}
          columns={columns}
          dataSource={data}
          pagination={{ defaultPageSize: 10, showSizeChanger: true, pageSizeOptions: ['5', '10', '20', '30'] }}/>
      </div>
    );
  }
}

export default withRouter(ListEmailTemplate);