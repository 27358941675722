import React, { Component } from 'react';
import { Form, Input, Button, notification, Row, Col } from 'antd';
import './CreateFunction.css';
import {NOTIFICATION_HEADER} from '../util/constants';
import { getVacancyFunctionById, updateVacancyFunction } from '../util/VacancyFunctionAPI';
import { Editor } from '@tinymce/tinymce-react';
import { Link } from 'react-router-dom';
import LoadingIndicator from "../common/LoadingIndicator";
import {privateUrls} from "../route/Routes";

class UpdateFunction extends Component {
  constructor( props ) {
    super(props);
    this.state = {
      jobFunction: {},
      isLoading: true
    };
  }

  handleSubmit = ( event ) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((error, formInput) => {
      if (error) {
        return;
      }

      formInput.id = this.props.match.params.id;

      updateVacancyFunction(formInput)
        .then(() => {
          notification.success({
            message: NOTIFICATION_HEADER,
            description: "Success! The job function has been updated.",
          });
          
          this.props.history.push(`${privateUrls.vacancyList}#jobFunction`);
        }).catch(error => {
        notification.error({
          message: NOTIFICATION_HEADER,
          description: error.message || 'Sorry! Something went wrong. Please try again!'
        });
      });
    });
  };

  componentDidMount() {
    getVacancyFunctionById(this.props.match.params.id, {
        signal: this.mSignal
      })
      .then(response => {
        this.setState({
          jobFunction: response.object,
          isLoading: false
        });
      }).catch(error => {
        this.setState({
          isLoading: false
        });
        notification.error({
          message: NOTIFICATION_HEADER,
          description: error.message || 'Sorry! Something went wrong. Please try again!'
        });
      });
  }

  render() {
    if (this.state.isLoading) {
      return <LoadingIndicator />;
    }

    const { jobFunction } = this.state;
    const { getFieldDecorator } = this.props.form;

    return (
      <div className="create-vacancy-container">
        <h1>Update Job Function</h1>
        <Form onSubmit={this.handleSubmit} className="job-function-form">
          <Form.Item label="Name" hasFeedback>
            {getFieldDecorator('name', {
              rules: [{
                required: true,
                message: 'Job function name is required.'
              }],
              initialValue: jobFunction.name
            })(
              <Input size="large" placeholder="Please input name of functions" />
            )}
          </Form.Item>
          <Form.Item label="Description" hasFeedback>
            {getFieldDecorator('description', {
              rules: [{
                required: true,
                message: 'Job function description is required.'
              }],
              initialValue: jobFunction.description,
              getValueFromEvent: e => e && e.target ? e.target.getContent() : e
            })(
              <Editor
                init={{
                  toolbar: 'bold italic | alignleft aligncenter alignright',
                  menubar: false,
                  branding: false
                }}
              />
            )}
          </Form.Item>
          <Form.Item>
            <Row>
              <Col span={8}>
                <Button type="primary" htmlType="submit" size="large" className="job-function-button">Save</Button>
              </Col>
              <Col span={8} offset={8}>
                <Link to={`${privateUrls.vacancyList}#jobFunction`}>
                  <Button className="job-function-button" size="large">Cancel</Button>
                </Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(UpdateFunction);