import {request} from "./BaseAPIRequest";
import {apiUrls} from "../route/Routes";

export const getUsers = () => {
  return request({
      url: apiUrls.user,
      method: 'GET'
  });
};

export const getUserById = id => {
  return request({
    url: `${apiUrls.user}/${id}`,
    method: 'GET'
  });
};

export const updateUser = payload => {
  return request({
      url: apiUrls.user,
      method: 'PUT',
      body: JSON.stringify(payload)
  });
};

export const createUser = payload => {
  return request({
      url: apiUrls.user,
      method: 'POST',
      body: JSON.stringify(payload)
  });
};

export const deleteUser = id => {
  return request({
    url: `${apiUrls.user}/${id}`,
      method: 'DELETE'
  });
};

export const checkUsernameAvailability = username => {
  return request({
      url: `${apiUrls.checkUsernameAvailability}?username=${username}`,
      method: 'GET'
  });
};