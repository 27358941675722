import React, {Component} from 'react';
import {Button, Col, Form, Input, notification, Row, Select} from 'antd';
import './CreateVacancy.css';
import {NOTIFICATION_HEADER} from '../util/constants';
import {createVacancy} from '../util/VacancyAPI';
import {getVacancyFunctions} from '../util/VacancyFunctionAPI';
import {Link} from 'react-router-dom';
import {TextEditor} from "../editor/TextEditor";
import {privateUrls} from "../route/Routes";

class CreateVacancy extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      vacancyFunctions: []
    };
  }

  handleSubmit = ( event ) => {
    event.preventDefault();

    this.props.form.validateFieldsAndScroll((error, formInput) => {
      if (error) {
        return;
      }

      createVacancy(formInput)
        .then(() => {
          notification.success({
            message: NOTIFICATION_HEADER,
            description: "Success! A new vacancy has been created.",
          });
          
          this.props.history.push(privateUrls.vacancyList);
        }).catch(error => {
          notification.error({
            message: NOTIFICATION_HEADER,
            description: error.message || 'Error! Something went wrong. Please try again later.'
          });
        });
    });
  };

  componentDidMount() {
    getVacancyFunctions()
      .then(response => {
        this.setState({
          vacancyFunctions: response,
          isLoading: false
        })
      }).catch(() => {
        this.setState({
          isLoading: false
        })
      });
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { vacancyFunctions } = this.state;

    return (
      <div className="create-vacancy-container">
        <h1>Create Vacancy</h1>
        <Form onSubmit={this.handleSubmit} className="signup-form">
          <Form.Item label="Position" hasFeedback>
            {getFieldDecorator('position', {
              rules: [{
                required: true, message: 'Vacancy position is required.',
              }]
            })(
              <Input size="large" placeholder="Please input vacancy position" />
            )}
          </Form.Item>
          <Form.Item label="Job Function" hasFeedback>
            {getFieldDecorator('vacancyFunction', {
              rules: [{
                required: true, message: 'Job function is required.',
              }],
              initialValue: ""
            })(
              <Select size="large">
                <Select.Option value=""> - Choose - </Select.Option>
                {
                  vacancyFunctions &&
                  vacancyFunctions.map((val, index) => <Select.Option key={index} value={val.id}>{val.name}</Select.Option>)
                }
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Location" hasFeedback>
            {getFieldDecorator('location', {
              rules: [{
                required: true, message: 'Vacancy position is required.',
              }]
            })(
              <Input size="large" placeholder="Please input vacancy location" />
            )}
          </Form.Item>
          <Form.Item label="Status" hasFeedback>
            {getFieldDecorator('status', {
              rules: [{
                required: true, message: 'Vacancy position is required.',
              }],
              initialValue: ""
            })(
              <Select size="large">
                <Select.Option value=""> - Choose - </Select.Option>
                <Select.Option value="inactive">Inactive</Select.Option>
                <Select.Option value="publish">Publish</Select.Option>
              </Select>
            )}
          </Form.Item>
          <Form.Item label="Description" hasFeedback>
            {getFieldDecorator('description', {
              rules: [{
                required: true, message: 'Vacancy description is required.',
              }],
              trigger: 'onEditorChange',
              getValueFromEvent: e => e && e.target ? e.target.getContent() : e
            })(
              <TextEditor />
            )}
          </Form.Item>
          <Form.Item label="SEO Title" hasFeedback>
            {getFieldDecorator('seoTitle', {
              rules: [{
                required: true, message: 'SEO Title is required.',
              }]
            })(
              <Input size="large" placeholder="Please input vacancy SEO title" />
            )}
          </Form.Item>
          <Form.Item label="SEO Description" hasFeedback>
            {getFieldDecorator('seoDescription', {
              rules: [{
                required: true, message: 'SEO Description is required.',
              }]
            })(
              <Input size="large" placeholder="Please input vacancy SEO description" />
            )}
          </Form.Item>
          <Form.Item>
            <Row>
              <Col span={8}>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="large"
                  className="create-vacancy-form-button">
                  Save
                </Button>
              </Col>
              <Col span={8} offset={8}>
                <Link to={privateUrls.vacancyList}>
                  <Button className="create-vacancy-form-button" size="large">Cancel</Button>
                </Link>
              </Col>
            </Row>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

export default Form.create()(CreateVacancy);
